import { useEffect, useState } from 'react';
import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import { Breadcrumbs, ServiceNavigation } from '../navigation';
import { ContentOrigin } from '../../interfaces';
import FormContent from './FormContent';
import toolsTopics from './tools-content';
import { appLayoutLabels } from '../../common/labels';

// Class CreateForm is a skeleton of a Single page create form using AWS-UI React components.
export default function CreateForm() {
  const [contentOrigins, setContentOrigins] = useState<ContentOrigin[]>([]);
  const [toolsTopic, setToolsTopic] = useState('main');
  const [toolsOpen, setToolsOpen] = useState(false);

  useEffect(() => {
    (async () => {
      // emulate request to the server using static json file
      const contentOrigins: ContentOrigin[] = (await import('../../data/content-origins.json')).default;
      setContentOrigins(contentOrigins);
    })();
  }, []);

  return (
    <AppLayout
      navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
      breadcrumbs={
        <Breadcrumbs
          items={[
            { text: 'Table demo', href: '#/table' },
            { text: 'Create page', href: '' }
          ]}
        />
      }
      content={
        <FormContent
          contentOrigins={contentOrigins}
          // Changes the Help panel content when the user clicks an 'info' link
          onTopicSelect={topic => {
            setToolsTopic(topic);
            setToolsOpen(true);
          }}
        />
      }
      contentType="form"
      tools={toolsTopics[toolsTopic]}
      toolsOpen={toolsOpen}
      onToolsChange={event => setToolsOpen(event.detail.open)}
      ariaLabels={appLayoutLabels}
    />
  );
}
