import { Component, useEffect, useState } from 'react';
import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import Flashbar, { FlashbarProps } from '@amzn/awsui-components-react/polaris/flashbar';
import { Breadcrumbs, ServiceNavigation } from '../navigation';
import ToolsPanel from './ToolsPanel';
import DetailsTable from './Table';
import { appLayoutLabels } from '../../common/labels';
import './styles.scss';
import { getMidwayJwtToken } from "../../auth/MidwayJwtToken";


const flashbarItemsLoading: FlashbarProps.MessageDefinition[] = [
    { type: 'info', content: 'Loading Reports', dismissible: true }
];

const flashbarItemsComplete: FlashbarProps.MessageDefinition[] = [
    { type: 'success', content: 'Reports Loaded Successfully', dismissible: true }
];

const flashbarItemsFailed: FlashbarProps.MessageDefinition[] = [
    { type: 'error', content: 'Reports Failed to load', dismissible: true }
];

const flashbarItemsForbidden: FlashbarProps.MessageDefinition[] = [
    // TODO: Add a link for bizmet team information so users can more easily address this issue
    //       Also maybe show bindle lock to show whether a user should have access or not
    { type: 'error', content: 'User is unauthorized to view Fondue. Please ask Business Metrics team if you believe this is a mistake', dismissible: true }
];

export enum States {
    loading,
    success,
    error,
    forbidden
}


export default function ReportsTableView() {

    const [state, setState] = useState<States>(States.loading)

    const [flashbar, setFlashbar] = useState<FlashbarProps.MessageDefinition[]>(flashbarItemsLoading)


    useEffect(() => {
        if(state === States.loading){
            setFlashbar(flashbarItemsLoading)
        }
        else if(state === States.success){
            setFlashbar(flashbarItemsComplete)
        }
        else if(state === States.forbidden){
            setFlashbar(flashbarItemsForbidden)
        }
        else{
            setFlashbar(flashbarItemsFailed)
        }
    }, [state, flashbar]);


    return (
        <AppLayout
            navigation={<ServiceNavigation />}
            notifications={<Flashbar items={flashbar} />}
            breadcrumbs={<Breadcrumbs items={[{ text: 'Reports Table', href: '' }]} />}
            content={<DetailsTable setState={setState} state={state} />}
            contentType="table"
            toolsOpen={false}
            tools={<ToolsPanel />}
            ariaLabels={appLayoutLabels}
        />
    );
}
