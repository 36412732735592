import './CacheBehaviorsPanel.scss';
import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import Checkbox from '@amzn/awsui-components-react/polaris/checkbox';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import Container from '@amzn/awsui-components-react/polaris/container';
import ExpandableSection from '@amzn/awsui-components-react/polaris/expandable-section';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import Header from '@amzn/awsui-components-react/polaris/header';
import Input from '@amzn/awsui-components-react/polaris/input';
import RadioGroup from '@amzn/awsui-components-react/polaris/radio-group';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import {
  ALLOWED_HTTP_METHOD_OPTIONS,
  COOKIE_OPTIONS,
  CURRENT_COMPRESSION_OPTIONS,
  FORWARD_HEADER_OPTIONS,
  QUERY_STRING_OPTIONS,
  VIEWER_PROTOCOL_POLICY_OPTIONS
} from './form-config';
import { useCheckbox, useInput, useRadio } from '../hooks';

// Footer content (Additional configuration section) for 'Cache behavior settings' form section
function BehaviorsFooter() {
  const cookies = useRadio('none');
  const queryString = useRadio('none');
  const contentCompression = useRadio('manual');
  const streaming = useCheckbox();
  const viewerAccess = useCheckbox();
  const type = useInput();
  const arn = useInput();
  return (
    <ExpandableSection header="Additional configuration" variant="footer">
      <SpaceBetween size="l">
        <div className="custom-details-text-component">
          <div className="custom-details-text-component__description">Path pattern</div>
          <Box margin={{ top: 'xs' }}>Default (*)</Box>
        </div>
        <FormField
          label="Cookies"
          description="Include all user cookies in the request URLs that it forwards to your origin."
          stretch={true}
        >
          <RadioGroup {...cookies} items={COOKIE_OPTIONS} />
        </FormField>
        <FormField
          label="Query string forwarding and caching"
          description="Query string parameters you want CloudFront to forward to the origin."
          stretch={true}
        >
          <RadioGroup {...queryString} items={QUERY_STRING_OPTIONS} />
        </FormField>
        <FormField label="Smooth streaming">
          <Checkbox data-testid="streaming-enabled-checkbox" {...streaming}>
            Enable Microsoft smooth streaming
          </Checkbox>
        </FormField>
        <FormField label="Viewer access">
          <Checkbox {...viewerAccess}>Require signed URL or signed cookie</Checkbox>
        </FormField>
        <FormField label="Content compression" stretch={true}>
          <RadioGroup {...contentCompression} items={CURRENT_COMPRESSION_OPTIONS} />
        </FormField>
        <FormField
          label="Lambda functions"
          description="Headers let you distinguish multiple origins in the same distribution from one another."
          stretch={true}
        >
          <ColumnLayout columns={3}>
            <FormField label="Type">
              <Input {...type} />
            </FormField>
            <FormField label="ARN">
              <Input {...arn} />
            </FormField>
            <div className="custom-header">
              <Button>Add lambda</Button>
            </div>
          </ColumnLayout>
        </FormField>
      </SpaceBetween>
    </ExpandableSection>
  );
}

export default function CacheBehaviorsPanel() {
  const viewerPolicy = useRadio('0');
  const httpMethods = useRadio('0');
  const forwardHeaders = useRadio('none');
  const minTtl = useInput('0');
  const maxTtl = useInput('31536000');
  const defaultTtl = useInput('86400');
  return (
    <Container header={<Header>Cache behavior settings</Header>} footer={<BehaviorsFooter />}>
      <SpaceBetween size="l">
        <FormField label="Viewer protocol policy" stretch={true}>
          <RadioGroup {...viewerPolicy} items={VIEWER_PROTOCOL_POLICY_OPTIONS} />
        </FormField>
        <FormField label="Allowed HTTP methods" stretch={true}>
          <RadioGroup {...httpMethods} items={ALLOWED_HTTP_METHOD_OPTIONS} />
        </FormField>
        <FormField label="Forward headers" description="Cache your objects based on header values" stretch={true}>
          <RadioGroup {...forwardHeaders} items={FORWARD_HEADER_OPTIONS} />
        </FormField>
        <FormField label="Object caching" description="Cache your objects based on header values" stretch={true}>
          <ColumnLayout columns={4}>
            <FormField label="Minimum TTL">
              <Input {...minTtl} type="number" data-testid="minimum-ttl-input" />
            </FormField>
            <FormField label="Maximum TTL">
              <Input {...maxTtl} type="number" />
            </FormField>
            <FormField label="Default TTL">
              <Input {...defaultTtl} type="number" />
            </FormField>
            <div className="custom-header">
              <Button>Set to default</Button>
            </div>
          </ColumnLayout>
        </FormField>
      </SpaceBetween>
    </Container>
  );
}
