import { Route, Switch} from 'react-router-dom';
import BasicLayout from './basic';
import ServiceHomepage from './home';
import CreateForm from './create';
import TableView from './table';
import ReportsTableView from './reports';
import ReportsFormView from "./reportform";
import ReportItemsTableView from  "./reportitems";
import EditReportsFormView from "./editreport";
import ReportResultView from "./viewreportresult";


// Class App is the "output" generated on every build,
// it is what you will see on the webpage.
export default function App() {
  return (
    <div>
        <Switch>
            <Route exact path="/" component={ServiceHomepage} />
            <Route path="/basic" component={BasicLayout} />
            <Route path="/create" component={CreateForm} />
            <Route path="/table" component={TableView} />
            <Route exact path="/reports" component={ReportsTableView} />
            <Route exact path="/reports/create" component={ReportsFormView} />
            <Route exact path="/reports/:report_id" component={ReportItemsTableView} />
            <Route exact path="/reports/:report_id/edit" component={EditReportsFormView} />
            <Route exact path="/reports/:report_id/result" component={ReportResultView} />
        </Switch>

    </div>
  );
}
