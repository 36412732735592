import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import { Breadcrumbs, ServiceNavigation } from '../navigation';
import Container from '@amzn/awsui-components-react/polaris/container';
import { appLayoutLabels } from '../../common/labels';
import {useEffect, useState} from "react";
import ToolsPanel from "../reports/ToolsPanel";
import ReportsEditFormPage from "./EditReportFormPage"
import { Report, ReportSchedule, FondueApi } from '../../fondue-api/generated-src';
import ReportItemsTable, {ReportTableProps} from "../reportitems/ReportItemsTable";
import FondueApiFactory from "../../fondue-api/FondueApiFactory";
import {useParams} from "react-router-dom";
import Flashbar, {FlashbarProps} from "@amzn/awsui-components-react/polaris/flashbar";
import { States } from "../../common/States";
import {Box} from "@amzn/awsui-components-react/polaris";
import Header from "@amzn/awsui-components-react/polaris/header";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import { getMidwayJwtToken } from "../../auth/MidwayJwtToken";


const flashbarItemsLoading: FlashbarProps.MessageDefinition[] = [
    { type: 'info', content: 'Loading report', dismissible: true }
];

export default function EditReportsFormView() {
    Object.freeze(Object.prototype);
    const { report_id }= useParams<{report_id: string}>();
    const [report, setReport] = useState<Report>();
    const [reportName, setReportName] = useState("");
    const [reportSchedule, setReportSchedule] = useState<ReportSchedule>();

    const [state, setState] = useState<States>(States.loading)
    const [flashbar, setFlashbar] = useState<FlashbarProps.MessageDefinition[]>([])

    async function getReport(FondueApi: FondueApi, counter: number) {
        await getMidwayJwtToken();
        if(counter < 3){
            await FondueApi.getReport(report_id)
                .then((response) =>{
                    setReport(response.data)
                    setReportName(response.data['name']);
                })
                .catch((e) => {
                    getReport(FondueApi, counter + 1)
                })
        }
    }

    async function getReportSchedule(FondueApi: FondueApi, counter: number) {
        if(counter < 3){
            await FondueApi.getReportSchedule(report_id)
                .then((response) =>{
                    setReportSchedule(response.data);
                })
                .catch((e) => {
                    getReportSchedule(FondueApi, counter + 1)
                })
        }
    }


    useEffect(() => {
        (async () => {
            if(state === States.loading){
                setFlashbar(flashbarItemsLoading)
            }
            const FondueApi = FondueApiFactory();

            await getReport(FondueApi, 0);
            await getReportSchedule(FondueApi, 0);
        })();
    }, []);

    return (
        <AppLayout
            navigation={<ServiceNavigation />}
            breadcrumbs={<Breadcrumbs items={[
                { text: 'Reports Table', href: '#/reports' },
                { text: reportName, href: '#/reports/'+ report_id },
                { text: 'Edit', href: '' }]} />}
            content=
                {
                    <Container>
                        <Header variant="h2">
                            Editing {reportName}
                        </Header>
                        {(report == undefined || reportSchedule == undefined) && <Spinner />}
                        {(!!report && !!reportSchedule) && <ReportsEditFormPage report={report} reportSchedule={reportSchedule} setState={setState} setFlashbar={setFlashbar}/>}
                    </Container>
                }
            contentType="default"
            notifications={<Flashbar items={flashbar} />}
            toolsOpen={false}
            tools={<ToolsPanel />}
            ariaLabels={appLayoutLabels}
        />
    );
}
