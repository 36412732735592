import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import { Breadcrumbs, ServiceNavigation } from '../navigation';
import { appLayoutLabels } from '../../common/labels';
import HelpPanel from '@amzn/awsui-components-react/polaris/help-panel';

function Content() {
  return (
    <div>
      Main content area (fill it in with components in <code>src/components/basic/index.tsx</code>
      !)
    </div>
  );
}

function Tools() {
  return (
    <HelpPanel header={<h2>CloudFront</h2>}>
      <ul>
        <li>
          <a href="http://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/Introduction.html">
            What is Amazon CloudFront?
          </a>
        </li>
        <li>
          <a href="http://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/GettingStarted.html">
            Getting started
          </a>
        </li>
        <li>
          <a href="http://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/distribution-working-with.html">
            Working with distributions
          </a>
        </li>
      </ul>
    </HelpPanel>
  );
}

// Class Basic is a skeleton of the basic App layout using AWS-UI React components.
export default function Basic() {
  return (
    <AppLayout
      navigation={<ServiceNavigation />}
      breadcrumbs={<Breadcrumbs items={[{ text: 'Basic page', href: '' }]} />}
      content={<Content />}
      contentType="default"
      tools={<Tools />}
      ariaLabels={appLayoutLabels}
    />
  );
}
