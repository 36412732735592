import Box from '@amzn/awsui-components-react/polaris/box';
import { RadioGroupProps } from '@amzn/awsui-components-react/polaris/radio-group';
import Link from '@amzn/awsui-components-react/polaris/link';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { ReportItem } from '../../fondue-api/generated-src';
import { ReactNode } from 'react';
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";

export const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<ReportItem>[] = [
    {
        id: 'metric_number',
        header: '#',
        cell: item => item.metric_number,
        width: '50px',
        sortingField: 'metric_number'
    },
    {
        id: 'name',
        cell: item  => item.name,
        header: 'Report Item Name',
        minWidth: '160px',
        sortingField: 'name'
    },
    {
        id: 'type',
        cell: item => item.type,
        header: 'Report Item Type',
        minWidth: '160px',
        sortingField: 'type'
    },
    {
        id: 'description',
        header: 'Description',
        cell: item => item.description || "-",
        minWidth: '180px'
    }
];

export function getMatchesCountText(count: number) {
    return count === 1 ? `1 match` : `${count} matches`;
}

interface EmptyStateProps {
    title: string;
    subtitle: string;
}
export function EmptyState({ title, subtitle }: EmptyStateProps) {
    return (
        <Box textAlign="center" color="inherit">
            <Box variant="strong" textAlign="center" color="inherit">
                {title}
            </Box>
            <Box variant="p" padding={{ bottom: 's' }} color="inherit">
                {subtitle}
            </Box>
        </Box>
    );
}

export const paginationLabels = {
    nextPageLabel: 'Next page',
    pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
    previousPageLabel: 'Previous page'
};

export const CUSTOM_PREFERENCE_OPTIONS: RadioGroupProps.RadioButtonDefinition[] = [
    { value: 'table', label: 'Table' },
    { value: 'cards', label: 'Cards' }
];
