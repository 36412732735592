import { useEffect, useState } from 'react';
import {useHistory, useParams} from "react-router-dom";
import { useCollection } from '@amzn/awsui-collection-hooks';
import Button from '@amzn/awsui-components-react/polaris/button';
import Header from '@amzn/awsui-components-react/polaris/header';
import FondueApiFactory from '../../fondue-api/FondueApiFactory';
import { Report, ReportItem, ReportSchedule, FondueApi } from '../../fondue-api/generated-src';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import {Box, ColumnLayout, Container} from "@amzn/awsui-components-react/polaris";
import {FlashbarProps} from "@amzn/awsui-components-react/polaris/flashbar";
import {States} from "../../common/States";
import ButtonDropdown from "@amzn/awsui-components-react/polaris/button-dropdown";
import { getMidwayJwtToken } from "../../auth/MidwayJwtToken";
import ExpandableSection from "@amzn/awsui-components-react/polaris/expandable-section";


export interface ReportContainerProps {
    setReportName: (name: string) => void;
    report: Report;
    setReport: (x: Report) => void;
    setState: (state: States) => void;
    setAddDisabled: (x: boolean) => void;
    setEditDisabled: (x: boolean) => void;
    setAddEditInfoDisabled: (x: boolean) => void;
}

export default function ReportContainer({report, setReport, setState, setReportName, setAddDisabled, setEditDisabled, setAddEditInfoDisabled}: ReportContainerProps) {
    Object.freeze(Object.prototype);
    const { report_id }= useParams<{report_id: string}>();
    const [reportContainerName, setReportContainerName] = useState("");

    //const [report, setReport] = useState<Report>();
    const [reportSchedule, setReportSchedule] = useState<ReportSchedule>();

    const ValueWithLabel = ({ label, children }) => (
        <div>
            <Box variant="awsui-key-label">{label}</Box>
            <div>{children}</div>
        </div>
    );

    const history = useHistory();

    async function deleteReport() {
        await getMidwayJwtToken();
        // Set State to deleting report
        setState(States.deletingReport);

        const FondueApi = FondueApiFactory();
        // Calling deleteReport
        await FondueApi.deleteReport(report_id)
            .then((response) => {
                // Set State to success in deleting report
                setState(States.deleteReportSuccess);
                // Redirect to Reports Page after deleting report
                history.push('/reports')
            })
            .catch((e)=> {
                // Set State to failed deleting report
                setState(States.failedDeleteReport);
            });
    }

    function showAddMetricComponent(){
        setAddDisabled(false);
        setEditDisabled(true);
        setAddEditInfoDisabled(true);
    }

    const addMetricButton = (
        <Button variant="primary" onClick={showAddMetricComponent}>
            Add Metric
        </Button>
    );

    async function buttonAction(event){
        switch (event.detail.id) {
            case "edit":
                break;
            case "delete":
                await deleteReport()
            case "viewresult":
                break;
        }
    }

    const actionButtons = (
        <ButtonDropdown
            id ="btnDropdown"
            items={[
                { text: "Edit Report", id: "edit", href:'#reports/' + report_id + '/edit'},
                { text: "View Report Result", id: "viewresult", href:'#reports/' + report_id + '/result'}
            ]}
            onItemClick={(event) => buttonAction(event)}
        >
            Actions
        </ButtonDropdown>
    )

    async function getReport(FondueApi: FondueApi, counter: number) {
        await getMidwayJwtToken();
        if(counter < 3){
            await FondueApi.getReport(report_id)
                .then((response) =>{
                    setReport(response.data)
                    setReportName(response.data['name']);
                    setReportContainerName(response.data['name']);
                })
                .catch((e) => {
                    getReport(FondueApi, counter + 1)
                })
        }
    }

    async function getReportSchedule(FondueApi: FondueApi, counter: number) {
        await getMidwayJwtToken();
        if(counter < 3){
            await FondueApi.getReportSchedule(report_id)
                .then((response) =>{
                    setReportSchedule(response.data);
                })
                .catch((e) => {
                    getReportSchedule(FondueApi, counter + 1)
                })
        }
    }
    useEffect(() => {
        (async () => {
            const FondueApi = FondueApiFactory();

            await getReport(FondueApi, 0);
            await getReportSchedule(FondueApi, 0);
        })();
    }, [setReport, FondueApiFactory]);


    return (
        <Container
            disableContentPaddings
            header={
                <Header
                variant="h2"
                actions={
                    <SpaceBetween direction="horizontal" size="m">
                        {actionButtons}
                    </SpaceBetween>
                }
                >
                    {reportContainerName}
                </Header>
            }
            footer={
            <ExpandableSection
                header="Report Details"
                variant="footer"
                defaultExpanded={false}
            >
                <ColumnLayout columns={2} variant="text-grid">
                    <SpaceBetween size="l">
                        <ValueWithLabel label="Report Name">{!!report && report.name || '-'}</ValueWithLabel>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                        <ValueWithLabel label="Report Owner">{!!report && report.owner || '-'}</ValueWithLabel>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                        <ValueWithLabel label="Report Category">{!!report && report.category || '-'}</ValueWithLabel>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                        <ValueWithLabel label="Report Project">{!!report && report.project || '-'}</ValueWithLabel>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                        <ValueWithLabel label="Report Permissions">{!!report && report.permissions || '-'}</ValueWithLabel>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                        <ValueWithLabel label="Report ID"> {report_id} </ValueWithLabel>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                        <ValueWithLabel label="Report State"> {!!reportSchedule && reportSchedule.State || '-'}</ValueWithLabel>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                        <ValueWithLabel label="Report Schedule"> {!!reportSchedule && reportSchedule.ScheduleExpression || '-'}</ValueWithLabel>
                    </SpaceBetween>
                </ColumnLayout>
            </ExpandableSection>
            }
            >
        </Container>
    );
}
