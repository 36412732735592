import {useCallback, useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import { useCollection } from '@amzn/awsui-collection-hooks';
import Button from '@amzn/awsui-components-react/polaris/button';
import Header from '@amzn/awsui-components-react/polaris/header';
import FondueApiFactory from '../../fondue-api/FondueApiFactory';
import { Report, ReportItem, FondueApi } from '../../fondue-api/generated-src';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import {Box, ColumnLayout, Container} from "@amzn/awsui-components-react/polaris";
import Form from "@amzn/awsui-components-react/polaris/form";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Textarea from '@amzn/awsui-components-react/polaris/textarea';
import Input from "@amzn/awsui-components-react/polaris/input";
import Select from '@amzn/awsui-components-react/polaris/select';
import { useCheckbox } from '../hooks';
import Checkbox from '@amzn/awsui-components-react/polaris/checkbox';
import { States } from "../../common/States";
import ReportItemsTable from "./ReportItemsTable";
import { getMidwayJwtToken } from "../../auth/MidwayJwtToken";


export interface ReportItemFormProps {
    setState: (state: States) => void;
    report: Report;
    setRefreshTable: (refresh: boolean) => void;
    setAddDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    setEditDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    setAddEditInfoDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ReportItemForm({setState, report, setRefreshTable, setAddDisabled, setEditDisabled, setAddEditInfoDisabled}: ReportItemFormProps) {
    Object.freeze(Object.prototype);
    const [itemName, setItemName] = useState("");
    const [itemType, setItemType] = useState("");
    const [itemQuery, setItemQuery] = useState("");
    const [itemDescription, setItemDescription] = useState("");
    const [itemFormat, setItemFormat] = useState("");
    const [selectionOption, setSelectionOption] = useState({})
    const [disabled, setDisabled] = useState(false);
    const [disableQuery, setDisableQuery] = useState(true);
    const [disableFormat, setDisableFormat] = useState(true);
    const [wowEnabled, setWowEnabled] = useState(false);
    const [disableWow, setDisableWow] = useState(true);
    const [wowEnabledString, setWowEnabledString] = useState('false');

    const itemTypeOptions = {
        'header': { "value": "header", "label": "header" },
        'query_single_result':  { "value": "query_single_result", "label": "query_single_result"},
        'query_multiple_result':  { "value": "query_multiple_result", "label": "query_multiple_result"}
    }

    async function createReportItem() {
        // If Item is of type header and query provided or name not provided, setState to invalid input
        if(itemType === 'header' && (itemQuery || itemName.trim() === "")){
            setState(States.invalid)
            return;
        }
        // If Item is of type query and query or name is not provided, setState to invalid input
        if(itemType !== 'header' && (itemQuery.trim() === "" || itemName.trim() ==="")){
            setState(States.invalid)
            return;
        }

        // If Item is of type query_multiple_result and no format is provided, setState to invalid input
        if(itemType === 'query_multiple_result' && itemFormat.trim() === ""){
            setState(States.invalid)
            return;
        }

        const FondueApi = FondueApiFactory();
        const reportItem: ReportItem = {
            id: '',
            name: itemName,
            type: itemType,
            report_id: report.id,
            bindle: report.bindle,
            report_item_config: {
                wow: wowEnabledString
            }
        };

        // Add query andor format for query item
        if(itemType !== 'header'){
            reportItem['query'] = itemQuery;

            // Add format for multiple result type
            if(itemType === 'query_multiple_result'){
                reportItem['format'] = itemFormat;
            }
        }

        // Set State to submitting
        setState(States.submitting)
        // Set Button to disabled
        setDisabled(true);

        // Calling createReportItem
        await getMidwayJwtToken();
        await FondueApi.createReportItem(reportItem)
            .then((response) => {
                // Clear out form
                clearFields();
                // Setting refreshTable to true
                setRefreshTable(true);
                // Set page to loading report
                setState(States.loading);
            })
            .catch((e)=> {
                // Set State to failedCreate
                setState(States.failedCreate)
                // Set Submit button to enabled
                setDisabled(false);
        });

    }

    function updateWowEnabledString(wowEnabled){
        if(wowEnabled === true) {
            setWowEnabledString('true')
            setWowEnabled(true)
        }
        else {
            setWowEnabledString('false')
            setWowEnabled(false)
        }
    }

    function setMetricTypeOption(event){
        setSelectionOption(event.detail.selectedOption);
        setItemType(event.detail.selectedOption.value);
        // Disable query and format field for header
        if(event.detail.selectedOption.value! === 'header'){
            setDisableQuery(true);
            setDisableFormat(true);
            setDisableWow(true);
        }
        // Disable format field for query_single_result
        else{
            setDisableQuery(false);
            if(event.detail.selectedOption.value! === 'query_multiple_result'){
                setDisableFormat(false);
                setDisableWow(true);
            }
            else{
                setDisableFormat(true);
                setDisableWow(false);
            }
        }
    }

    function clearFields(){
        // Clear out form
        setItemName('');
        setItemQuery('');
        setItemType('');
        setItemDescription('');
        setItemFormat('');
        setSelectionOption({});
        setDisableQuery(true);
        setDisableFormat(true);
        setDisabled(false);
        setWowEnabled(false);
        setAddDisabled(true);
        setEditDisabled(true);
        setAddEditInfoDisabled(false);
    }

    return (
        <Container
            header={
                <Header variant="h2" description="">
                    Add Metric
                </Header>
            }
        >
            <Form
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button id="cancel" variant="normal" onClick={clearFields} disabled={disabled}>
                            Cancel
                        </Button>
                        <Button id="submit" variant="primary" onClick={createReportItem} disabled={disabled}>
                            Submit
                        </Button>
                    </SpaceBetween>
                }
            >
                <FormField
                    id='metrictype'
                    label="Metric Type"
                >
                    <Select
                        selectedOption={selectionOption}
                        placeholder="Select metric type"
                        options={[
                            itemTypeOptions['header'],
                            itemTypeOptions['query_single_result'],
                            itemTypeOptions['query_multiple_result']
                        ]}
                        onChange={event => {
                            setMetricTypeOption(event);

                        }}
                    />

                </FormField>
                <FormField
                    id='name'
                    label="Metric Name" description="Name of metric">
                    <Input
                        value = {itemName}
                        onChange={event =>
                            setItemName(event.detail.value)
                        }
                    />
                </FormField>
                <FormField
                    id='description'
                    label={
                        <span>
                            Metric Description <i> - optional </i>{" "}
                        </span>
                    }
                >
                    <Input
                        value = {itemDescription}
                        onChange={event =>
                            setItemDescription(event.detail.value)}
                    />
                </FormField>
                <FormField
                    id='query'
                    label={
                        <span>
                            SQL Query  <i> - required for query type </i>{" "}
                        </span>
                    }
                    description="SQL query that defines your metric.  Only applies to metrics of query_single_result
                                 and query_multiple_result type. IAM_WBR_DT will resolve to Sunday date of a given
                                 weeks run"
                >
                    <Textarea
                        disabled={disableQuery}
                        value={itemQuery}
                        rows={15}
                        onChange={({detail}) =>
                            setItemQuery(detail.value)}
                        placeholder={"SELECT test from wbr.test WHERE end_date = 'IAM_WBR_DT' LIMIT 1"}
                    />
                </FormField>
                <FormField
                    id='format'
                    label={
                        <span>
                            Query Format <i> - required for query_multiple_result </i>{" "}
                        </span>
                    }
                    description="Query format for query_multiple_result. If more than one column is defined in query
                                 please provide string of format with {} around each column. Example {account} - {count}"
                >
                    <Input
                        disabled={disableFormat}
                        value = {itemFormat}
                        onChange={event =>
                            setItemFormat(event.detail.value)}
                        placeholder={"{col1} - {col2}"}
                    />
                </FormField>
                <FormField 
                    id = 'additionalmetriccomponents'
                    label={
                        <span>
                            Additional Metric Components
                        </span>
                    }
                    description="Select additonal calculations to add to the metric"
                >
                    <ColumnLayout columns={2} variant='default'>
                        <SpaceBetween size="xs">
                            <Checkbox
                                id='wowcheckbox'
                                disabled={disableWow}
                                onChange={({ detail }) =>
                                updateWowEnabledString(detail.checked)
                                }
                                checked={wowEnabled}
                                >
                                wow
                            </Checkbox>
                        </SpaceBetween>
                    </ColumnLayout>
                </FormField>
            </Form>
        </Container>
    );
}