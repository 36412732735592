import { FileUpload, Form, Modal, SpaceBetween, Button, FormField, Input, Textarea, TextContent, Toggle, TokenGroup, TokenGroupProps, Checkbox, Select, SelectProps, Multiselect, Box } from "@amzn/awsui-components-react";
import React, { useState } from "react";
import { ReportItem } from "../../../fondue-api/generated-src";
import { IsDerReportItemType } from "../../../common/ReportTypes";
import DerFilterForm from "./derfilter/DerFilterForm";
import DerMatchFilterForm from "./derfilter/DerMatchFilterForm";
import { OptionDefinition } from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces";

const EmptyFilterError = "Cannot be empty";
const EmptyCustomColumnError = "Select at least one output column"

const PolicyDEROutputTypes = {
    counts: { value: "counts", label: "Policies Count", description: "Number of matching policies" },
    account_counts: { value: "account_counts", label: "Accounts Count", description: "Number of accounts that have matching policies" },
    account_numbers: { value: "account_numbers", label: "Account IDs", disabled: false, description: "Accounts owning matching policies" },
    policy_ids: { value: "policy_ids", label: "Policy Document IDs", disabled: false, description: "Policy document IDs" },
    custom_cols: { value: "custom_cols", label: "Custom Columns", disabled: false, description: "Select custom columns" }
};

const CustomColumnsOutputTypes = {
    account_id: { value: "account_id", label: "Account ID"},
    document_id: { value: "document_id", label: "Document ID"},
    arn: { value: "arn", label: "Policy ARN"},
    policy_type: { value: "policy_type", label: "Policy Type"},
    policy_stat: { value: "policy_stat", label: "Policy Statement JSON"}
}

const ManagedPoliciesOptions = {
    "-": { value: "-", label: "All Policies"},
    awsmanaged: { value: "awsmanaged", label: "AWS Managed Policies"},
    customermanaged: { value: "customermanaged", label: "Customer Managed Policies"},
    nonmanaged: {value: "nonmanaged", label: "Inline Policies"},
    trustpolicy: {value: "trustpolicy", label: "Trust Policies"}
}

const PolicyDEREffectTypes = {
    allow: { value: "allow", label: "Allow" },
    deny: { value: "deny", label: "Deny" }
};

export interface DerReportProps {
    clearFields: () => void;
    createReportItem: (reportItemConfig?: {}) => void;
    disabled: boolean;
    metricTypeState: [SelectProps.Option, (any) => void];
    itemNameState: [string, React.Dispatch<React.SetStateAction<string>>];
    itemDescriptionState: [string, React.Dispatch<React.SetStateAction<string>>];
    itemQueryState: [string, React.Dispatch<React.SetStateAction<string>>];
    isEditing?: boolean;
    resetFields?: () => void;
    reportItem?: ReportItem | null;

    enablePolicyFilterForm?: boolean; //Enable or disable filter form
    itemTypeOptions: any;
    submitReportItemLoading: boolean;
}

/**Retrieve filter value for simple filters ({filters:[{value: 'filter-value'}]}) */
function getSimpleFilterValue(filterConfig: string): string | undefined {
    if (filterConfig === undefined) {
        return undefined;
    }

    try {
        const jsonConfig = JSON.parse(filterConfig);
        return jsonConfig?.["filters"]?.[0]?.["value"];
    } catch {
        // backward compatibility with simple value
        return filterConfig;
    }
}

export default function DerReportItemForm({ metricTypeState, itemNameState, itemDescriptionState, itemQueryState, clearFields, createReportItem, disabled, submitReportItemLoading, ...props }: DerReportProps) {
    const [selectionOption, setMetricTypeOption] = metricTypeState;
    const [itemName, setItemName] = itemNameState;
    const [itemDescription, setItemDescription] = itemDescriptionState;

    let der_config = props.reportItem?.report_item_config?.["der_config"];

    const [useSqlQuery, _] = useState(der_config?.["use_filter_form"] == false);

    const [derOutputType, setDerOutputType] = useState<SelectProps.Option>(PolicyDEROutputTypes[der_config?.["output_type"] ?? "counts"]);

    const [managedPoliciesOption, setManagedPoliciesOption] = useState<SelectProps.Option>(ManagedPoliciesOptions[der_config?.["managed_policy"] ?? "-"]);

    const [useEffectFilter, setUseEffectFilter] = useState(der_config?.["effect"] != undefined);
    const [effect, setEffect] = useState<SelectProps.Option>(PolicyDEREffectTypes[getSimpleFilterValue(der_config?.["effect"]) ?? "allow"]);

    const [useActionFilter, setUseActionFilter] = useState(der_config?.["action"] != undefined);
    const [actionFilter, setActionFilter] = useState<string>(der_config?.["action"] ?? "");
    const [actionError, setActionError] = useState("");

    const [useResourceFilter, setUseResourceFilter] = useState(der_config?.["resource"] != undefined);
    const [resourceFilter, setResourceFilter] = useState<string>(der_config?.["resource"] ?? "");
    const [resourceError, setResourceError] = useState("");

    const [useConditionFilter, setUseConditionFilter] = useState(der_config?.["condition"] != undefined);
    const [conditionFilter, setConditionFilter] = useState<string>(der_config?.["condition"] ?? "");
    const [conditionError, setConditionError] = useState("");

    const [useAccountFilter, setUseAccountFilter] = useState(der_config?.["account"] != undefined);
    const [accountFilter, setAccountFilter] = useState<string>(der_config?.["account"] ?? "");
    const [accountError, setAccountError] = useState("");

    const [usePrincipalFilter, setUsePrincipalFilter] = useState(der_config?.["principal"] != undefined);
    const [principalFilter, setPrincipalFilter] = useState<string>(der_config?.["principal"] ?? "");
    const [principalError, setPrincipalError] = useState("");

    const [useServicePrincipalFilter, setUseServicePrincipalFilter] = useState(!!der_config?.service_principal);
    const [servicePrincipalFilter, setServicePrincipalFilter] = useState<string>(der_config?.service_principal ?? "");
    const [servicePrincipalError, setServicePrincipalError] = useState<string | null>(null);

    const customColumns: string[] = der_config?.["custom_columns"];
    const [customColumnVisible, setCustomColumnVisible] = useState(isCustomColumnsOutput(derOutputType));
    const [customColumnType, setCustomColumnType] = useState<readonly SelectProps.Option[]>(customColumns?.map((opt) => CustomColumnsOutputTypes[opt]) ?? []);
    const [customColumnError, setCustomColumnError] = useState("");

    const [fileValue, setFileValue] = useState<readonly File[]>([]);
    const [uploadProgress, setUploadProgress] = useState<number>(0)
    const [uploadModalVisible, setUploadModalVisible] = useState(false)
    const [uploadModalLoading, setUploadModalLoading] = useState(false)
    interface SelectedOption {
           label?: string;
           value?: string;
        }
    const [selectedCSV, setSelectedCSV] = useState<SelectedOption>()

    const uploadFile = async (file: File) => {
        setUploadProgress(10); // TODO: placeholder, need to add actual upload status tracking
        //const presigned_url = "https://pbraedon-testing.s3.amazonaws.com/csv_upload/first_test?AWSAccessKeyId=ASIA4J75PTDCALS3YBKE&Signature=H3ntj4mqLiZylQKbitMsWSSmn04%3D&content-type=text%2Fcsv&x-amz-security-token=IQoJb3JpZ2luX2VjEFUaCXVzLWVhc3QtMSJHMEUCIQCo7j1ryfEO5BaYAqMN%2Fab2lYSgF6r3%2Fwz%2Bk9YNZutBrQIgR7kw9pY1G1PIoRf6QOaVjV%2B6T5okFXPNU3TRw4KPMWEq%2FwII3v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARACGgw4NDYxMDMyODgwMDQiDKm9dEWoGlNUxqF0BirTAgOQLbw7BljGMpC6AusAvDTiYMZ0fLnCYwS56VrBIw28pD3d691GZJvBEgsItkdG%2F7XCWdEWWkHpmavfRf6y0OQRaGznIMg8dMRBh1uREdJwEU5IWgqJtFTJJ97CYEaMlyjCD75ZB6pbBW172m%2BhtG09r5W8GkJWwD0CHkC86CZHL3lW44OPROMlWy6eVsqjVeXWf%2FFZ9eKK1Mf%2B2uoYdaBd3dVdmdyeHty5NYJsS%2FUqR8h0UJEBclK6sGnxnX9F23c8ad%2BIgy%2FUnOng7bb5doyoYGisjK3cgZkv1SIj64cnnFH0am%2BVn5YDG3%2FMA1TtBSacQOSCe5qQTfLEWdn3SoQ%2BArGsRAISgjR82BGyABtItbDe75mqH5QBccs9ah48riQVTHCi%2FGiuoLMLoFhNsSoKC9Pask%2FjP4nk%2F%2Fto48qDsw4AeuPMmt5jYo5dgdj9xaujhTCrwIizBjqeAX076ByjMPesm6oPyFjXBzQxkKg0oFMiNOGkGp4A2GJYjjXbIjk%2B3RoppT8E2rxFFyssGAGDeSnIRW8Vdwaf113r0Tkb2H2wlGQImy4qb7Ug1t4tibOm%2FzMykcN%2BcbFJ7HoNUcrLEpJT6l1rkjUQvnDvV3M%2BwPsa2BS245llZeOCrCwZAXUEzxe4w3TIaT8SmQEAdNcdE0o2V4Q617eq&Expires=1717710398";
        //await putFile(file,presigned_url);
        /*fetch(`${apiGatewayUrl}/generate_upload_url?file_name=${file.name}`, {headers: requestHeaders})
            .then(response => response.json())
            .then(async (data) => {
                console.log(data)
                await putImage(file, data.presigned_url)
            })*/

        const reader = new FileReader()
                reader.onload = (e: any) => {
                  const data = e.target.result
                  //setAllText(data)
                  //setFileName(`- ${file.name}`)
                  console.log(data)
                }
                reader.readAsText(file)
    }

    const putFile = async (file: File, url: string) => {
        setUploadProgress(50) // TODO: placeholder, need to add actual upload status tracking
        const headers = new Headers({
            "Content-Type": file.type,
            "Content-Length": file.size.toString()
        });
        console.log(headers)
        const response = await fetch(url, {
            method: "PUT",
            headers,
            body: file
        })
        // TODO: if response status != 200, set error

        // TODO: placeholder; after a few seconds, clear file and progress
        setUploadProgress(100);
        setTimeout(() => {
            setUploadProgress(0);
        }, 3000);
    }

    /** Return True if current DER output is 'custom_cols'*/
    function isCustomColumnsOutput(x: OptionDefinition) {
        return x.value == PolicyDEROutputTypes.custom_cols.value;
    }

    /** OutputType change logic */
    function setOutputType(x: OptionDefinition) {
        if (isCustomColumnsOutput(x)) {
            setCustomColumnVisible(true);
        } else {
            setCustomColumnVisible(false);
        }
        setDerOutputType(x);
    }

    function getSelectedOption(): SelectProps.Option {
        if (props?.reportItem?.report_item_config?.["der_config"]?.["der_type"]) {
            return props.itemTypeOptions[props.reportItem.report_item_config["der_config"]["der_type"]]
        }

        return selectionOption;
    }

    function clearValidationErrors() {
        setActionError("");
        setResourceError("");
        setConditionError("");
        setAccountError("");
        setPrincipalError("");
        setServicePrincipalError("");
        setCustomColumnError("");
    }

    function isFilterEmpty(filter): boolean {
        return (filter == "" || JSON.parse(filter).filters.length == 0);
    }

    function validatePolicyForm(): boolean {
        let validationPass = true;
        clearValidationErrors();
        if (useActionFilter && isFilterEmpty(actionFilter)) {
            setActionError(EmptyFilterError);
            validationPass = false;
        }

        if (useResourceFilter && isFilterEmpty(resourceFilter)) {
            setResourceError(EmptyFilterError);
            validationPass = false;
        }

        if (useConditionFilter && isFilterEmpty(conditionFilter)) {
            setConditionError(EmptyFilterError);
            validationPass = false;
        }

        if (useAccountFilter && isFilterEmpty(accountFilter)) {
            setAccountError(EmptyFilterError);
            validationPass = false;
        }

        if (usePrincipalFilter && isFilterEmpty(principalFilter)) {
            setPrincipalError(EmptyFilterError);
            validationPass = false;
        }

        if (useServicePrincipalFilter && isFilterEmpty(servicePrincipalFilter)) {
            setServicePrincipalError(EmptyFilterError);
            validationPass = false;
        }

        if (isCustomColumnsOutput(derOutputType) && customColumnType.length == 0) {
            setCustomColumnError(EmptyCustomColumnError);
            validationPass = false;
        }

        return validationPass;
    }

    function createDerReportItem() {
        if (validatePolicyForm()) {
            createReportItem(buildReportItemConfig());
        }
    }

    function buildReportItemConfig(): any {
        der_config = {
            use_filter_form: !useSqlQuery,
            output_type: derOutputType.value,
            der_type: props.isEditing ? getSelectedOption().value : selectionOption.value // Always set der_type from config when editing
        }

        der_config["custom_columns"] = customColumnType.map((opt) => opt.value)

        if (managedPoliciesOption.value != "-") {
            der_config["managed_policy"] = managedPoliciesOption.value
        }

        if (useEffectFilter) {
            der_config["effect"] = JSON.stringify({filters: [{value: effect.value}]});
        }

        if (useActionFilter) {
            der_config["action"] = actionFilter;
        }

        if (useResourceFilter) {
            der_config["resource"] = resourceFilter;
        }

        if (useConditionFilter) {
            der_config["condition"] = conditionFilter;
        }

        if (usePrincipalFilter) {
            der_config["principal"] = principalFilter
        }

        if (useServicePrincipalFilter) {
            der_config["service_principal"] = servicePrincipalFilter
        }

        if (useAccountFilter) {
            der_config["account"] = accountFilter;
        }

        return { "der_config": der_config };
    }

    return (
        <Form actions={
            <SpaceBetween direction="horizontal" size="xs">
                <Button id="cancel" variant="normal" onClick={clearFields} disabled={disabled}>
                    Cancel
                </Button>
                {
                    props.isEditing === true
                    && <Button id="reset" variant="normal" onClick={props.resetFields ?? (() => { })} disabled={disabled}>
                        Reset
                    </Button>
                }
                <Button id="submit" variant="primary" loading={submitReportItemLoading} onClick={() => createDerReportItem()} disabled={disabled}>
                    Submit
                </Button>
            </SpaceBetween>
        }>
        <SpaceBetween size='xs'>
            <FormField label="Type">
                <Select
                    disabled={props.isEditing && IsDerReportItemType(props.reportItem! ?? selectionOption.value)}
                    controlId="metrictype"
                    selectedOption={getSelectedOption()}
                    placeholder="Select item type"
                    options={Object.keys(props.itemTypeOptions).map((itemType) => { return props.itemTypeOptions[itemType] })}
                    onChange={event => {
                        setMetricTypeOption(event);
                    }}
                />
            </FormField>
            <FormField
                id="name"
                label="Item Name" description="Name of item">
                <Input
                    value={itemName}
                    onChange={event =>
                        setItemName(event.detail.value)
                    }
                />
            </FormField>
            <FormField
                id="description"
                label={
                    <span>
                        Item Description <i> - optional </i>{" "}
                    </span>
                }
            >
                <Textarea
                    value={itemDescription}
                    rows={3}
                    onChange={({ detail }) =>
                        setItemDescription(detail.value)}
                />
            </FormField>
            <React.Fragment>
                <FormField id="output-type" label="Output Columns">
                    <Select selectedOption={derOutputType}
                        onChange={({ detail }) => setOutputType(detail.selectedOption)}
                        options={Object.keys(PolicyDEROutputTypes).map((key) => PolicyDEROutputTypes[key])} />
                </FormField>
                {customColumnVisible && <FormField id="custom-columns" errorText={customColumnError}>
                    <Multiselect selectedOptions={customColumnType}
                                onChange={({detail}) => setCustomColumnType(detail.selectedOptions)}
                                placeholder="Choose output columns"
                                options={Object.keys(CustomColumnsOutputTypes).map((key) => CustomColumnsOutputTypes[key])}
                    /> 
                </FormField>}
                <Box>
                <FormField id="managed-policies-option" label="Managed Policies Option" description={
                    <span>See more information on managed and inline policies <a title="Managed policies and inline policies" href="https://docs.aws.amazon.com/IAM/latest/UserGuide/access_policies_managed-vs-inline.html" target="_blank">here</a></span>
                }>
                    <Select options={Object.keys(ManagedPoliciesOptions).map((key) => ManagedPoliciesOptions[key])} selectedOption={managedPoliciesOption}
                            onChange={({detail}) => setManagedPoliciesOption(detail.selectedOption)}/>
                </FormField>
                <FormField id="use-effect-filter">
                    <Checkbox checked={useEffectFilter} onChange={({ detail }) => { setUseEffectFilter(detail.checked) }}
                        description={
                            <React.Fragment>
                                Filter based on <a target="_blank" href="https://docs.aws.amazon.com/IAM/latest/UserGuide/reference_policies_elements_effect.html">policy effects</a>
                            </React.Fragment>
                        }>
                        Effect Filter
                    </Checkbox>
                </FormField>
                {useEffectFilter && <FormField id="effect-filter">
                    <Select disabled={!useEffectFilter}
                        selectedOption={effect}
                        onChange={({ detail }) => { setEffect(detail.selectedOption) }}
                        options={Object.keys(PolicyDEREffectTypes).map((key) => PolicyDEREffectTypes[key])}
                        selectedAriaLabel="Selected" />
                </FormField>}
                <FormField id="use-action-filter">
                    <Checkbox checked={useActionFilter} onChange={({ detail }) => { setUseActionFilter(detail.checked) }}
                        description={
                            <React.Fragment>
                                Filter based on <a target="_blank" href="https://docs.aws.amazon.com/IAM/latest/UserGuide/reference_policies_elements_action.html">policy actions</a>
                            </React.Fragment>
                        }>
                        Action Filter
                    </Checkbox>
                </FormField>
                {useActionFilter && <FormField id="action-filter" constraintText="(Use % as wildcard)" errorText={actionError}>
                    <DerFilterForm field="action" filters={actionFilter} onUpdate={(value) => setActionFilter(value)} />
                </FormField>}
                <FormField id="use-resource-filter">
                    <Checkbox checked={useResourceFilter} onChange={({ detail }) => { setUseResourceFilter(detail.checked) }}
                        description={
                            <React.Fragment>
                                Filter based on <a target="_blank" href="https://docs.aws.amazon.com/IAM/latest/UserGuide/reference_policies_elements_resource.html">policy resources</a>
                            </React.Fragment>
                        }>
                        Resource Filter
                    </Checkbox>
                </FormField>
                {useResourceFilter && <FormField id="resource-filter" constraintText="(Use % as wildcard)" errorText={resourceError}>
                    <DerFilterForm field="resource" filters={resourceFilter} onUpdate={(value) => setResourceFilter(value)} />
                </FormField>}
                <FormField id="use-condition-filter">
                    <Checkbox checked={useConditionFilter} onChange={({ detail }) => { setUseConditionFilter(detail.checked) }}
                        description={
                            <React.Fragment>
                                Filter based on <a target="_blank" href="https://docs.aws.amazon.com/IAM/latest/UserGuide/reference_policies_elements_condition.html">policy conditions</a>
                            </React.Fragment>
                        }>
                        Condition Filter
                    </Checkbox>
                </FormField>
                {useConditionFilter &&
                    <FormField id="condition-filter" constraintText="(Use % as wildcard)" errorText={conditionError}>
                        <DerFilterForm field="condition" filters={conditionFilter} onUpdate={(value) => setConditionFilter(value)} />
                    </FormField>}
                <FormField id="use-principal-filter">
                    <Checkbox checked={usePrincipalFilter} onChange={({ detail }) => { setUsePrincipalFilter(detail.checked) }}
                        description={
                            <React.Fragment>
                                Filter based on <a target="_blank" href="https://docs.aws.amazon.com/IAM/latest/UserGuide/reference_policies_elements_principal.html">policy principal conditions</a>
                            </React.Fragment>
                        }>
                        Policy Principal Filter
                    </Checkbox>
                </FormField>
                {usePrincipalFilter && <FormField id="principal-filter" constraintText="(Use % as wildcard)" errorText={principalError}>
                    <DerFilterForm field="principal" filters={principalFilter} onUpdate={(value) => setPrincipalFilter(value)} />
                </FormField>}

                <FormField>
                    <Checkbox
                        checked={useServicePrincipalFilter}
                        onChange={({ detail }) => setUseServicePrincipalFilter(detail.checked)}
                        description={
                            <>
                                Filter based on{" "}
                                <a
                                    target="_blank"
                                    href="https://docs.aws.amazon.com/IAM/latest/UserGuide/reference_policies_elements_principal.html#principal-services"
                                >
                                    service principal conditions
                                </a>
                            </>
                        }
                    >
                        Service Principal Filter
                    </Checkbox>
                </FormField>

                {useServicePrincipalFilter && (
                    <FormField constraintText="(Use % as wildcard)" errorText={servicePrincipalError}>
                        <DerFilterForm
                            field="service_principal"
                            filters={servicePrincipalFilter}
                            onUpdate={(value) => setServicePrincipalFilter(value)}
                        />
                    </FormField>
                )}

                <FormField id="use-account-filter">
                    <Checkbox checked={useAccountFilter} onChange={({ detail }) => { setUseAccountFilter(detail.checked) }}
                        description={
                            <React.Fragment>
                                Filter based on owning account's id
                            </React.Fragment>
                        }>
                        Account Filter
                    </Checkbox>
                </FormField>
                {useAccountFilter && <FormField id="account-filter" constraintText="(Use % as wildcard)" errorText={accountError}>
                    <DerMatchFilterForm field="account" filters={accountFilter} onUpdate={(value) => {setAccountFilter(value);console.log(value)}} />
                </FormField>}
                </Box>
            </React.Fragment>
            
        </SpaceBetween>
        </Form>
    );
}