/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI Fondue API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface DisableReportItemRequest
 */
export interface DisableReportItemRequest {
    /**
     * 
     * @type {string}
     * @memberof DisableReportItemRequest
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface GenerateReportRequest
 */
export interface GenerateReportRequest {
    /**
     * 
     * @type {string}
     * @memberof GenerateReportRequest
     */
    'report_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateReportRequest
     */
    'wbr_date'?: string;
}
/**
 * 
 * @export
 * @interface GenerateReportResponse
 */
export interface GenerateReportResponse {
    /**
     * 
     * @type {string}
     * @memberof GenerateReportResponse
     */
    'executionArn'?: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateReportResponse
     */
    'startDate'?: string;
}
/**
 * 
 * @export
 * @interface MessageResponse
 */
export interface MessageResponse {
    /**
     * 
     * @type {string}
     * @memberof MessageResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface Report
 */
export interface Report {
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'owner': string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'project'?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'category'?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'rule_schedule'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Report
     */
    'report_item_order': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'permissions'?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'bindle': string;
}
/**
 * 
 * @export
 * @interface ReportItem
 */
export interface ReportItem {
    /**
     * 
     * @type {string}
     * @memberof ReportItem
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ReportItem
     */
    'report_id': string;
    /**
     * 
     * @type {string}
     * @memberof ReportItem
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ReportItem
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof ReportItem
     */
    'query'?: string;
    /**
     * 
     * @type {ReportItemReportItemConfig}
     * @memberof ReportItem
     */
    'report_item_config'?: ReportItemReportItemConfig;
    /**
     * 
     * @type {number}
     * @memberof ReportItem
     */
    'metric_number'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportItem
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportItem
     */
    'format'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportItem
     */
    'bindle': string;
}
/**
 * 
 * @export
 * @interface ReportItemReportItemConfig
 */
export interface ReportItemReportItemConfig {
    /**
     * 
     * @type {string}
     * @memberof ReportItemReportItemConfig
     */
    'format'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportItemReportItemConfig
     */
    'wow'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportItemReportItemConfig
     */
    'dod'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportItemReportItemConfig
     */
    'yoy'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportItemReportItemConfig
     */
    'ytd'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportItemReportItemConfig
     */
    'arr'?: number;
}
/**
 * 
 * @export
 * @interface ReportSchedule
 */
export interface ReportSchedule {
    /**
     * 
     * @type {string}
     * @memberof ReportSchedule
     */
    'report_id': string;
    /**
     * 
     * @type {string}
     * @memberof ReportSchedule
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ReportSchedule
     */
    'ScheduleExpression': string;
    /**
     * 
     * @type {string}
     * @memberof ReportSchedule
     */
    'State': string;
}
/**
 * 
 * @export
 * @interface RetrieveCFUrlRequest
 */
export interface RetrieveCFUrlRequest {
    /**
     * 
     * @type {string}
     * @memberof RetrieveCFUrlRequest
     */
    'report_id'?: string;
}
/**
 * 
 * @export
 * @interface RetrieveCFUrlResponse
 */
export interface RetrieveCFUrlResponse {
    /**
     * 
     * @type {string}
     * @memberof RetrieveCFUrlResponse
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface RetrieveReportRequest
 */
export interface RetrieveReportRequest {
    /**
     * 
     * @type {string}
     * @memberof RetrieveReportRequest
     */
    'report_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RetrieveReportRequest
     */
    'reportName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RetrieveReportRequest
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RetrieveReportRequest
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof RetrieveReportRequest
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof RetrieveReportRequest
     */
    'fileType'?: string;
}
/**
 * 
 * @export
 * @interface RetrieveReportResponse
 */
export interface RetrieveReportResponse {
    /**
     * 
     * @type {string}
     * @memberof RetrieveReportResponse
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface ValidatePermissionRequest
 */
export interface ValidatePermissionRequest {
    /**
     * 
     * @type {string}
     * @memberof ValidatePermissionRequest
     */
    'action': string;
    /**
     * 
     * @type {string}
     * @memberof ValidatePermissionRequest
     */
    'report_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidatePermissionRequest
     */
    'item_id'?: string;
}
/**
 * 
 * @export
 * @interface ValidatePermissionResponse
 */
export interface ValidatePermissionResponse {
    /**
     * 
     * @type {string}
     * @memberof ValidatePermissionResponse
     */
    'action'?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidatePermissionResponse
     */
    'report_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidatePermissionResponse
     */
    'item_id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ValidatePermissionResponse
     */
    'authorized'?: boolean;
}

/**
 * FondueApi - axios parameter creator
 * @export
 */
export const FondueApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a report
         * @param {Report} report A *Report* to be added
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReport: async (report: Report, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'report' is not null or undefined
            assertParamExists('createReport', 'report', report)
            const localVarPath = `/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(report, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a report item
         * @param {ReportItem} reportItem A *Report Item* to be added
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReportItem: async (reportItem: ReportItem, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportItem' is not null or undefined
            assertParamExists('createReportItem', 'reportItem', reportItem)
            const localVarPath = `/report_items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportItem, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Report by id
         * @param {string} reportId The id of the report to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReport: async (reportId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('deleteReport', 'reportId', reportId)
            const localVarPath = `/reports/{reportId}`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Report Item by id
         * @param {string} reportItemId The id of the report to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReportItem: async (reportItemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportItemId' is not null or undefined
            assertParamExists('deleteReportItem', 'reportItemId', reportItemId)
            const localVarPath = `/report_items/{reportItemId}`
                .replace(`{${"reportItemId"}}`, encodeURIComponent(String(reportItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Disables specific Report Item
         * @param {string} reportItemId The id of the report item to disable
         * @param {DisableReportItemRequest} disableReportItemRequest Disable report item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableReportItem: async (reportItemId: string, disableReportItemRequest: DisableReportItemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportItemId' is not null or undefined
            assertParamExists('disableReportItem', 'reportItemId', reportItemId)
            // verify required parameter 'disableReportItemRequest' is not null or undefined
            assertParamExists('disableReportItem', 'disableReportItemRequest', disableReportItemRequest)
            const localVarPath = `/report_items/{reportItemId}/disable`
                .replace(`{${"reportItemId"}}`, encodeURIComponent(String(reportItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(disableReportItemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Disables a specific report schedule
         * @param {string} reportScheduleId The id of the report schedule to disable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableReportSchedule: async (reportScheduleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportScheduleId' is not null or undefined
            assertParamExists('disableReportSchedule', 'reportScheduleId', reportScheduleId)
            const localVarPath = `/disable_report_schedule/{reportScheduleId}`
                .replace(`{${"reportScheduleId"}}`, encodeURIComponent(String(reportScheduleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enables specific Report Item
         * @param {string} reportItemId The id of the report item to enable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableReportItem: async (reportItemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportItemId' is not null or undefined
            assertParamExists('enableReportItem', 'reportItemId', reportItemId)
            const localVarPath = `/report_items/{reportItemId}/enable`
                .replace(`{${"reportItemId"}}`, encodeURIComponent(String(reportItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enables a specific report schedule
         * @param {string} reportScheduleId The id of the report schedule to enable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableReportSchedule: async (reportScheduleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportScheduleId' is not null or undefined
            assertParamExists('enableReportSchedule', 'reportScheduleId', reportScheduleId)
            const localVarPath = `/enable_report_schedule/{reportScheduleId}`
                .replace(`{${"reportScheduleId"}}`, encodeURIComponent(String(reportScheduleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enables a specific report schedule
         * @param {string} reportId The id of the report to generate
         * @param {GenerateReportRequest} generateReportRequest A Report Id and WBR date to generate the report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateReportSchedule: async (reportId: string, generateReportRequest: GenerateReportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('generateReportSchedule', 'reportId', reportId)
            // verify required parameter 'generateReportRequest' is not null or undefined
            assertParamExists('generateReportSchedule', 'generateReportRequest', generateReportRequest)
            const localVarPath = `/generate_report/{reportId}`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateReportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific report
         * @param {string} reportId The id of the report to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReport: async (reportId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('getReport', 'reportId', reportId)
            const localVarPath = `/reports/{reportId}`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific report item
         * @param {string} reportItemId The id of the report item to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportItem: async (reportItemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportItemId' is not null or undefined
            assertParamExists('getReportItem', 'reportItemId', reportItemId)
            const localVarPath = `/report_items/{reportItemId}`
                .replace(`{${"reportItemId"}}`, encodeURIComponent(String(reportItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a specific report schedule
         * @param {string} reportScheduleId The id of the report schedule to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportSchedule: async (reportScheduleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportScheduleId' is not null or undefined
            assertParamExists('getReportSchedule', 'reportScheduleId', reportScheduleId)
            const localVarPath = `/report_schedule/{reportScheduleId}`
                .replace(`{${"reportScheduleId"}}`, encodeURIComponent(String(reportScheduleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all report items
         * @param {number} [limit] How many items to return at one time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReportItems: async (limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/report_items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all report items in given report
         * @param {string} reportId The id of the report to retrieve for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReportMetrics: async (reportId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('listReportMetrics', 'reportId', reportId)
            const localVarPath = `/reports/{reportId}/list`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all reports
         * @param {number} [limit] How many items to return at one time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReports: async (limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve CF Signed Url
         * @param {string} reportId The id of the report to retrieve for
         * @param {RetrieveCFUrlRequest} retrieveCFUrlRequest A Report Id to check user permissions on
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCloudFrontSignedUrl: async (reportId: string, retrieveCFUrlRequest: RetrieveCFUrlRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('retrieveCloudFrontSignedUrl', 'reportId', reportId)
            // verify required parameter 'retrieveCFUrlRequest' is not null or undefined
            assertParamExists('retrieveCloudFrontSignedUrl', 'retrieveCFUrlRequest', retrieveCFUrlRequest)
            const localVarPath = `/reports/{reportId}/cfsignedurl`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(retrieveCFUrlRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve Report Result
         * @param {string} reportId The id of the report to retrieve for
         * @param {RetrieveReportRequest} retrieveReportRequest A Report Id and WBR date to retrieve result
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveReportResult: async (reportId: string, retrieveReportRequest: RetrieveReportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('retrieveReportResult', 'reportId', reportId)
            // verify required parameter 'retrieveReportRequest' is not null or undefined
            assertParamExists('retrieveReportResult', 'retrieveReportRequest', retrieveReportRequest)
            const localVarPath = `/reports/{reportId}/result`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(retrieveReportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a specific report
         * @param {string} reportId The id of the report to update
         * @param {Report} report A *Report* to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReport: async (reportId: string, report: Report, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('updateReport', 'reportId', reportId)
            // verify required parameter 'report' is not null or undefined
            assertParamExists('updateReport', 'report', report)
            const localVarPath = `/reports/{reportId}`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(report, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a specific report item
         * @param {string} reportItemId The id of the report item to update
         * @param {ReportItem} reportItem A *Report Item* to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReportItem: async (reportItemId: string, reportItem: ReportItem, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportItemId' is not null or undefined
            assertParamExists('updateReportItem', 'reportItemId', reportItemId)
            // verify required parameter 'reportItem' is not null or undefined
            assertParamExists('updateReportItem', 'reportItem', reportItem)
            const localVarPath = `/report_items/{reportItemId}`
                .replace(`{${"reportItemId"}}`, encodeURIComponent(String(reportItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportItem, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a specific report schedule
         * @param {string} reportScheduleId The id of the report schedule to update
         * @param {ReportSchedule} reportSchedule A *Report Schedule* to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReportSchedule: async (reportScheduleId: string, reportSchedule: ReportSchedule, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportScheduleId' is not null or undefined
            assertParamExists('updateReportSchedule', 'reportScheduleId', reportScheduleId)
            // verify required parameter 'reportSchedule' is not null or undefined
            assertParamExists('updateReportSchedule', 'reportSchedule', reportSchedule)
            const localVarPath = `/report_schedule/{reportScheduleId}`
                .replace(`{${"reportScheduleId"}}`, encodeURIComponent(String(reportScheduleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportSchedule, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verify if a user has access to given permission or not
         * @param {ValidatePermissionRequest} validatePermissionRequest User details and type of permission needed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePermission: async (validatePermissionRequest: ValidatePermissionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'validatePermissionRequest' is not null or undefined
            assertParamExists('validatePermission', 'validatePermissionRequest', validatePermissionRequest)
            const localVarPath = `/permission`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(validatePermissionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FondueApi - functional programming interface
 * @export
 */
export const FondueApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FondueApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a report
         * @param {Report} report A *Report* to be added
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createReport(report: Report, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createReport(report, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a report item
         * @param {ReportItem} reportItem A *Report Item* to be added
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createReportItem(reportItem: ReportItem, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createReportItem(reportItem, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Report by id
         * @param {string} reportId The id of the report to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReport(reportId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReport(reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Report Item by id
         * @param {string} reportItemId The id of the report to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReportItem(reportItemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReportItem(reportItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Disables specific Report Item
         * @param {string} reportItemId The id of the report item to disable
         * @param {DisableReportItemRequest} disableReportItemRequest Disable report item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disableReportItem(reportItemId: string, disableReportItemRequest: DisableReportItemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disableReportItem(reportItemId, disableReportItemRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Disables a specific report schedule
         * @param {string} reportScheduleId The id of the report schedule to disable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disableReportSchedule(reportScheduleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportSchedule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disableReportSchedule(reportScheduleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Enables specific Report Item
         * @param {string} reportItemId The id of the report item to enable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enableReportItem(reportItemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enableReportItem(reportItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Enables a specific report schedule
         * @param {string} reportScheduleId The id of the report schedule to enable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enableReportSchedule(reportScheduleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportSchedule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enableReportSchedule(reportScheduleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Enables a specific report schedule
         * @param {string} reportId The id of the report to generate
         * @param {GenerateReportRequest} generateReportRequest A Report Id and WBR date to generate the report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateReportSchedule(reportId: string, generateReportRequest: GenerateReportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateReportSchedule(reportId, generateReportRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a specific report
         * @param {string} reportId The id of the report to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReport(reportId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Report>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReport(reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a specific report item
         * @param {string} reportItemId The id of the report item to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportItem(reportItemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Report>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportItem(reportItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves a specific report schedule
         * @param {string} reportScheduleId The id of the report schedule to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportSchedule(reportScheduleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportSchedule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportSchedule(reportScheduleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List all report items
         * @param {number} [limit] How many items to return at one time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listReportItems(limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listReportItems(limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List all report items in given report
         * @param {string} reportId The id of the report to retrieve for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listReportMetrics(reportId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listReportMetrics(reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List all reports
         * @param {number} [limit] How many items to return at one time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listReports(limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Report>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listReports(limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve CF Signed Url
         * @param {string} reportId The id of the report to retrieve for
         * @param {RetrieveCFUrlRequest} retrieveCFUrlRequest A Report Id to check user permissions on
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveCloudFrontSignedUrl(reportId: string, retrieveCFUrlRequest: RetrieveCFUrlRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RetrieveCFUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveCloudFrontSignedUrl(reportId, retrieveCFUrlRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve Report Result
         * @param {string} reportId The id of the report to retrieve for
         * @param {RetrieveReportRequest} retrieveReportRequest A Report Id and WBR date to retrieve result
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveReportResult(reportId: string, retrieveReportRequest: RetrieveReportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RetrieveReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveReportResult(reportId, retrieveReportRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a specific report
         * @param {string} reportId The id of the report to update
         * @param {Report} report A *Report* to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReport(reportId: string, report: Report, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Report>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReport(reportId, report, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a specific report item
         * @param {string} reportItemId The id of the report item to update
         * @param {ReportItem} reportItem A *Report Item* to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReportItem(reportItemId: string, reportItem: ReportItem, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReportItem(reportItemId, reportItem, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a specific report schedule
         * @param {string} reportScheduleId The id of the report schedule to update
         * @param {ReportSchedule} reportSchedule A *Report Schedule* to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReportSchedule(reportScheduleId: string, reportSchedule: ReportSchedule, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportSchedule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReportSchedule(reportScheduleId, reportSchedule, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Verify if a user has access to given permission or not
         * @param {ValidatePermissionRequest} validatePermissionRequest User details and type of permission needed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validatePermission(validatePermissionRequest: ValidatePermissionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidatePermissionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validatePermission(validatePermissionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FondueApi - factory interface
 * @export
 */
export const FondueApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FondueApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a report
         * @param {Report} report A *Report* to be added
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReport(report: Report, options?: any): AxiosPromise<void> {
            return localVarFp.createReport(report, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a report item
         * @param {ReportItem} reportItem A *Report Item* to be added
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReportItem(reportItem: ReportItem, options?: any): AxiosPromise<void> {
            return localVarFp.createReportItem(reportItem, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Report by id
         * @param {string} reportId The id of the report to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReport(reportId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteReport(reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Report Item by id
         * @param {string} reportItemId The id of the report to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReportItem(reportItemId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteReportItem(reportItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Disables specific Report Item
         * @param {string} reportItemId The id of the report item to disable
         * @param {DisableReportItemRequest} disableReportItemRequest Disable report item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableReportItem(reportItemId: string, disableReportItemRequest: DisableReportItemRequest, options?: any): AxiosPromise<MessageResponse> {
            return localVarFp.disableReportItem(reportItemId, disableReportItemRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Disables a specific report schedule
         * @param {string} reportScheduleId The id of the report schedule to disable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableReportSchedule(reportScheduleId: string, options?: any): AxiosPromise<ReportSchedule> {
            return localVarFp.disableReportSchedule(reportScheduleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Enables specific Report Item
         * @param {string} reportItemId The id of the report item to enable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableReportItem(reportItemId: string, options?: any): AxiosPromise<MessageResponse> {
            return localVarFp.enableReportItem(reportItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Enables a specific report schedule
         * @param {string} reportScheduleId The id of the report schedule to enable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableReportSchedule(reportScheduleId: string, options?: any): AxiosPromise<ReportSchedule> {
            return localVarFp.enableReportSchedule(reportScheduleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Enables a specific report schedule
         * @param {string} reportId The id of the report to generate
         * @param {GenerateReportRequest} generateReportRequest A Report Id and WBR date to generate the report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateReportSchedule(reportId: string, generateReportRequest: GenerateReportRequest, options?: any): AxiosPromise<GenerateReportResponse> {
            return localVarFp.generateReportSchedule(reportId, generateReportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a specific report
         * @param {string} reportId The id of the report to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReport(reportId: string, options?: any): AxiosPromise<Report> {
            return localVarFp.getReport(reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a specific report item
         * @param {string} reportItemId The id of the report item to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportItem(reportItemId: string, options?: any): AxiosPromise<Report> {
            return localVarFp.getReportItem(reportItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves a specific report schedule
         * @param {string} reportScheduleId The id of the report schedule to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportSchedule(reportScheduleId: string, options?: any): AxiosPromise<ReportSchedule> {
            return localVarFp.getReportSchedule(reportScheduleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all report items
         * @param {number} [limit] How many items to return at one time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReportItems(limit?: number, options?: any): AxiosPromise<Array<ReportItem>> {
            return localVarFp.listReportItems(limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all report items in given report
         * @param {string} reportId The id of the report to retrieve for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReportMetrics(reportId: string, options?: any): AxiosPromise<ReportItem> {
            return localVarFp.listReportMetrics(reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all reports
         * @param {number} [limit] How many items to return at one time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReports(limit?: number, options?: any): AxiosPromise<Array<Report>> {
            return localVarFp.listReports(limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve CF Signed Url
         * @param {string} reportId The id of the report to retrieve for
         * @param {RetrieveCFUrlRequest} retrieveCFUrlRequest A Report Id to check user permissions on
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCloudFrontSignedUrl(reportId: string, retrieveCFUrlRequest: RetrieveCFUrlRequest, options?: any): AxiosPromise<RetrieveCFUrlResponse> {
            return localVarFp.retrieveCloudFrontSignedUrl(reportId, retrieveCFUrlRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve Report Result
         * @param {string} reportId The id of the report to retrieve for
         * @param {RetrieveReportRequest} retrieveReportRequest A Report Id and WBR date to retrieve result
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveReportResult(reportId: string, retrieveReportRequest: RetrieveReportRequest, options?: any): AxiosPromise<RetrieveReportResponse> {
            return localVarFp.retrieveReportResult(reportId, retrieveReportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a specific report
         * @param {string} reportId The id of the report to update
         * @param {Report} report A *Report* to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReport(reportId: string, report: Report, options?: any): AxiosPromise<Report> {
            return localVarFp.updateReport(reportId, report, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a specific report item
         * @param {string} reportItemId The id of the report item to update
         * @param {ReportItem} reportItem A *Report Item* to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReportItem(reportItemId: string, reportItem: ReportItem, options?: any): AxiosPromise<ReportItem> {
            return localVarFp.updateReportItem(reportItemId, reportItem, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a specific report schedule
         * @param {string} reportScheduleId The id of the report schedule to update
         * @param {ReportSchedule} reportSchedule A *Report Schedule* to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReportSchedule(reportScheduleId: string, reportSchedule: ReportSchedule, options?: any): AxiosPromise<ReportSchedule> {
            return localVarFp.updateReportSchedule(reportScheduleId, reportSchedule, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Verify if a user has access to given permission or not
         * @param {ValidatePermissionRequest} validatePermissionRequest User details and type of permission needed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePermission(validatePermissionRequest: ValidatePermissionRequest, options?: any): AxiosPromise<ValidatePermissionResponse> {
            return localVarFp.validatePermission(validatePermissionRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FondueApi - object-oriented interface
 * @export
 * @class FondueApi
 * @extends {BaseAPI}
 */
export class FondueApi extends BaseAPI {
    /**
     * 
     * @summary Create a report
     * @param {Report} report A *Report* to be added
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public createReport(report: Report, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).createReport(report, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a report item
     * @param {ReportItem} reportItem A *Report Item* to be added
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public createReportItem(reportItem: ReportItem, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).createReportItem(reportItem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Report by id
     * @param {string} reportId The id of the report to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public deleteReport(reportId: string, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).deleteReport(reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Report Item by id
     * @param {string} reportItemId The id of the report to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public deleteReportItem(reportItemId: string, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).deleteReportItem(reportItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Disables specific Report Item
     * @param {string} reportItemId The id of the report item to disable
     * @param {DisableReportItemRequest} disableReportItemRequest Disable report item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public disableReportItem(reportItemId: string, disableReportItemRequest: DisableReportItemRequest, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).disableReportItem(reportItemId, disableReportItemRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Disables a specific report schedule
     * @param {string} reportScheduleId The id of the report schedule to disable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public disableReportSchedule(reportScheduleId: string, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).disableReportSchedule(reportScheduleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Enables specific Report Item
     * @param {string} reportItemId The id of the report item to enable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public enableReportItem(reportItemId: string, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).enableReportItem(reportItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Enables a specific report schedule
     * @param {string} reportScheduleId The id of the report schedule to enable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public enableReportSchedule(reportScheduleId: string, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).enableReportSchedule(reportScheduleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Enables a specific report schedule
     * @param {string} reportId The id of the report to generate
     * @param {GenerateReportRequest} generateReportRequest A Report Id and WBR date to generate the report
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public generateReportSchedule(reportId: string, generateReportRequest: GenerateReportRequest, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).generateReportSchedule(reportId, generateReportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a specific report
     * @param {string} reportId The id of the report to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public getReport(reportId: string, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).getReport(reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a specific report item
     * @param {string} reportItemId The id of the report item to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public getReportItem(reportItemId: string, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).getReportItem(reportItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves a specific report schedule
     * @param {string} reportScheduleId The id of the report schedule to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public getReportSchedule(reportScheduleId: string, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).getReportSchedule(reportScheduleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all report items
     * @param {number} [limit] How many items to return at one time
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public listReportItems(limit?: number, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).listReportItems(limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all report items in given report
     * @param {string} reportId The id of the report to retrieve for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public listReportMetrics(reportId: string, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).listReportMetrics(reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all reports
     * @param {number} [limit] How many items to return at one time
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public listReports(limit?: number, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).listReports(limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve CF Signed Url
     * @param {string} reportId The id of the report to retrieve for
     * @param {RetrieveCFUrlRequest} retrieveCFUrlRequest A Report Id to check user permissions on
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public retrieveCloudFrontSignedUrl(reportId: string, retrieveCFUrlRequest: RetrieveCFUrlRequest, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).retrieveCloudFrontSignedUrl(reportId, retrieveCFUrlRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve Report Result
     * @param {string} reportId The id of the report to retrieve for
     * @param {RetrieveReportRequest} retrieveReportRequest A Report Id and WBR date to retrieve result
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public retrieveReportResult(reportId: string, retrieveReportRequest: RetrieveReportRequest, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).retrieveReportResult(reportId, retrieveReportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a specific report
     * @param {string} reportId The id of the report to update
     * @param {Report} report A *Report* to be updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public updateReport(reportId: string, report: Report, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).updateReport(reportId, report, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a specific report item
     * @param {string} reportItemId The id of the report item to update
     * @param {ReportItem} reportItem A *Report Item* to be updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public updateReportItem(reportItemId: string, reportItem: ReportItem, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).updateReportItem(reportItemId, reportItem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a specific report schedule
     * @param {string} reportScheduleId The id of the report schedule to update
     * @param {ReportSchedule} reportSchedule A *Report Schedule* to be updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public updateReportSchedule(reportScheduleId: string, reportSchedule: ReportSchedule, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).updateReportSchedule(reportScheduleId, reportSchedule, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Verify if a user has access to given permission or not
     * @param {ValidatePermissionRequest} validatePermissionRequest User details and type of permission needed
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public validatePermission(validatePermissionRequest: ValidatePermissionRequest, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).validatePermission(validatePermissionRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


