import { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import SideNavigation, { SideNavigationProps } from '@amzn/awsui-components-react/polaris/side-navigation';
import Link from '@amzn/awsui-components-react/polaris/link';
import BreadcrumbGroup, { BreadcrumbGroupProps } from '@amzn/awsui-components-react/polaris/breadcrumb-group';

const HOME = { text: 'Fondue Reporting', href: '#/' };
const items: SideNavigationProps.Item[] = [
  {
    type: 'section',
    text: 'Reporting',
    items: [
      { type: 'link', text: 'Reports', href: '#/reports' }
    ]
  }
];

export function Breadcrumbs({ items }: { items: BreadcrumbGroupProps.Item[] }) {
  return <BreadcrumbGroup items={items} />;
}

// Class ServiceNavigation is the Side Navigation component that is used in BasicLayout, CreateForm, ServiceHomepage, and Table flows.
// Implement like this: <ServiceNavigation />
class ServiceNavigationLocal extends Component<RouteComponentProps> {
  // If the provided link is empty, do not redirect pages
  onFollowHandler(event: CustomEvent<SideNavigationProps.FollowDetail>) {
    event.preventDefault();
    if (event.detail.href) {
      this.props.history.push(event.detail.href.substring(1));
    }
  }

  render() {
    return (
      <SideNavigation
        header={HOME}
        items={items}
        activeHref={`#${this.props.location.pathname}`}
        onFollow={this.onFollowHandler.bind(this)}
      />
    );
  }
}

export const ServiceNavigation = withRouter(ServiceNavigationLocal);
