import React, { Component, useEffect, useState } from 'react';
import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import Flashbar, { FlashbarProps } from '@amzn/awsui-components-react/polaris/flashbar';
import { Breadcrumbs, ServiceNavigation } from '../navigation';
import { appLayoutLabels } from '../../common/labels';
import { Box, Container, Grid, ColumnLayout, Header, Table, SpaceBetween, } from '@amzn/awsui-components-react/polaris';
import Button from "@amzn/awsui-components-react/polaris/button";
import ButtonDropdown from "@amzn/awsui-components-react/polaris/button-dropdown";
import { useHistory } from 'react-router-dom';
import { useParams } from "react-router-dom";
import DatePicker from "@amzn/awsui-components-react/polaris/date-picker";
import Form from "@amzn/awsui-components-react/polaris/form";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import FondueApiFactory from '../../fondue-api/FondueApiFactory';
import { Report, ReportItem, ReportSchedule, RetrieveReportRequest, RetrieveCFUrlRequest, FondueApi } from '../../fondue-api/generated-src';
import {States} from "../../common/States";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Input from "@amzn/awsui-components-react/polaris/input";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import { getMidwayJwtToken } from "../../auth/MidwayJwtToken";



export interface ReportResultDateSelectionProps {
    reportName: string;
    setState: (state: States) => void;
    setFlashbar: (x: FlashbarProps.MessageDefinition[]) => void;
}

export default function ReportResultDateSelection({reportName, setState, setFlashbar}: ReportResultDateSelectionProps) {
    Object.freeze(Object.prototype);
    const { report_id }= useParams<{report_id: string}>();
    const [startDateValue, setStartDateValue] = useState("");
    const [endDateValue, setEndDateValue] = useState("");

    const [fileName, setFileName] = useState("");

    const [downloadDisabled, setDownloadDisabled] = useState(true);
    const [retrievePdfDisabled, setRetrievePdfDisabled] = useState(true);


    const [cloudFrontUrl, setCloudFrontUrl] = useState("");
    const [pdfUrl, setPdfUrl] = useState("");

    const [showAlert, setShowAlert] = useState(false);



    const flashbarReportRetrieving: FlashbarProps.MessageDefinition[] = [
        {
            type: 'info', content: 'Retrieving report result', dismissible: true,
            onDismiss: () => setFlashbar([])
        }
    ];

    const flashbarReportRetrievePrompt: FlashbarProps.MessageDefinition[] = [
        {
            type: 'success', content: 'Select end date and click Retrieve PDF to view report preview',
            action: <Button onClick={retrieveReportPDF}>View PDF Preview</Button>,
            dismissible: true,
            onDismiss: () => setFlashbar([])
        }
    ];

    const flashbarInvalidInput: FlashbarProps.MessageDefinition[] = [
        {
            type: 'error', content: 'Invalid date range selected', dismissible: true,
            onDismiss: () => setFlashbar([])
        }
    ];

    const flashbarRetrieveSuccess: FlashbarProps.MessageDefinition[] = [
        {
            type: 'success', content: 'Successfully retrieved report result', dismissible: true,
            onDismiss: () => setFlashbar([])
        }
    ];

    const flashbarFileNotFound: FlashbarProps.MessageDefinition[] = [
        {
            type: 'error', content: 'File not found for report and date range', dismissible: true,
            onDismiss: () => setFlashbar([])
        }
    ];

    const flashbarReportRetrievingError: FlashbarProps.MessageDefinition[] = [
        {
            type: 'error', content: 'Error retrieving report result', dismissible: true,
            onDismiss: () => setFlashbar([])
        }
    ];

    const flashbarRetrievingCFUrl: FlashbarProps.MessageDefinition[] = [
        {
            type: 'info', content: 'Retrieving Permissions on Viewing Report Result', dismissible: true,
            onDismiss: () => setFlashbar([])
        }
    ];

    const flashbarReportAccessDenied: FlashbarProps.MessageDefinition[] = [
        {
            type: 'error', content: 'User does not have permission to view this Report Result', dismissible: true,
            onDismiss: () => setFlashbar([])
        }
    ];


    function clearFields(){
        setStartDateValue("");
        setEndDateValue("");
    }

    function downloadReportS3(url: string){
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    }

    async function retrieveReportExcel(FondueApi: FondueApi, retrieveReportRequest: RetrieveReportRequest) {
        await getMidwayJwtToken();
        await FondueApi.retrieveReportResult(report_id, retrieveReportRequest)
            .then((response) =>{
                // Trigger download of Excel in Browser if Cloudfront Signed Url present
                if(cloudFrontUrl != '') {
                    downloadReportS3(response.data.url!);

                    setState(States.success);
                    setFlashbar(flashbarRetrieveSuccess);
                }
                // If CloudFrontUrl is empty show access denied
                else{
                    setFlashbar(flashbarReportAccessDenied);
                }

            })
            .catch((e) => {
                // Unauthorized error
                if(e.response.status === 403){
                    setFlashbar(flashbarReportAccessDenied);
                }
                // File not found error
                else if(e.response.status === 404){
                    setFlashbar(flashbarFileNotFound);
                }
                else{
                    setFlashbar(flashbarReportRetrievingError);
                }

                setState(States.error);
            })
    }

    function downloadExcel(){
        if(startDateValue == "" || endDateValue == ""){
            setFlashbar(flashbarInvalidInput);
            return
        }

        setFlashbar(flashbarReportRetrieving);
        const FondueApi = FondueApiFactory();

        const retrieveReportRequest: RetrieveReportRequest = {
            report_id: report_id,
            reportName: reportName,
            fileName: fileName,
            startDate: startDateValue,
            endDate: endDateValue,
            fileType: 'xlsx'

        }

        retrieveReportExcel(FondueApi, retrieveReportRequest);
    }


    async function downloadReportPdf(FondueApi: FondueApi, retrieveReportRequest: RetrieveReportRequest) {
        await getMidwayJwtToken();
        await FondueApi.retrieveReportResult(report_id, retrieveReportRequest)
            .then((response) =>{
                setPdfUrl(response.data.url!);
                setState(States.success);
                setFlashbar(flashbarRetrieveSuccess);
            })
            .catch((e) => {
                setPdfUrl("");

                // Unauthorized error
                if(e.response.status === 403){
                    setFlashbar(flashbarReportAccessDenied);
                }
                // File not found error
                else if(e.response.status === 404){
                    setFlashbar(flashbarFileNotFound);
                    setShowAlert(true);
                }
                else{
                    setFlashbar(flashbarReportRetrievingError);
                }
                setState(States.error);
            })
    }

    async function retrieveCloudfrontSignedUrl(FondueApi: FondueApi, retrieveReportRequest: RetrieveReportRequest) {
        await getMidwayJwtToken();
        await FondueApi.retrieveCloudFrontSignedUrl(report_id, retrieveReportRequest)
            .then((response) =>{
                setCloudFrontUrl(response.data.url!)
                setDownloadDisabled(false);
                setRetrievePdfDisabled(false);
                setFlashbar(flashbarReportRetrievePrompt);
            })
            .catch((e) => {
                if(e.response.status === 403){
                    setFlashbar(flashbarReportAccessDenied);
                    setDownloadDisabled(true);
                    setRetrievePdfDisabled(true);
                }
                setState(States.error);
            })
    }


    function retrieveReportPDF(){
        setFlashbar(flashbarReportRetrieving);
        const FondueApi = FondueApiFactory();

        const retrieveReportRequest: RetrieveReportRequest = {
            report_id: report_id,
            reportName: reportName,
            fileName: fileName,
            startDate: startDateValue,
            endDate: endDateValue,
            fileType: 'pdf'
        }

        downloadReportPdf(FondueApi, retrieveReportRequest);

        setRetrievePdfDisabled(false);
    }

    function retrieveCloudFrontSignedUrl(){
        const FondueApi = FondueApiFactory();
        setFlashbar(flashbarRetrievingCFUrl);

        const retrieveCFUrlRequest: RetrieveCFUrlRequest = {
            report_id: report_id
        }

        retrieveCloudfrontSignedUrl(FondueApi, retrieveCFUrlRequest);
    }

    function setStartDate(date){
        setStartDateValue(date)
        const startDate = new Date(date);
        const endDate = new Date(startDate.setDate(startDate.getDate() + 70));

        // Get endDate in yyyy-mm-dd format
        const endDateString = endDate.toISOString().split("T")[0];
        setEndDateValue(endDateString);
    }

    function setEndDate(date){
        setEndDateValue(date)
        const endDate = new Date(date);
        const startDate = new Date(endDate.setDate(endDate.getDate() - 70));

        // Get endDate in yyyy-mm-dd format
        const startDateString = startDate.toISOString().split("T")[0];
        setStartDateValue(startDateString);
    }

    function getPreviousSunday(date = new Date()) {
        const previousSunday = new Date();
        previousSunday.setDate(date.getDate() - date.getDay());
        return previousSunday;
    }

    // Default to most recent wbr date
    async function setRecentWBRDate(){
        const date = new Date();
        const sunday = getPreviousSunday(date);

        const offset = sunday.getTimezoneOffset();
        const sundayOffsetDate = new Date(sunday.getTime() - (offset*60*1000));

        setEndDate(sundayOffsetDate.toISOString().split("T")[0])

    }


    function buttonAction(event){
        switch (event.detail.id) {
            case "dxlsx":
                downloadExcel();
                break;
        }
    }

    const actionButtons = (
        <ButtonDropdown
            id ="btnDropdown"
            items={[
                { text: "Download Excel", id: "dxlsx", disabled: downloadDisabled },
                { text: "Generate Report [Beta]", id: "genrep", disabled: true },
            ]}
            onItemClick={(event) => buttonAction(event)}
        >
            Actions
        </ButtonDropdown>
    )


    useEffect(() => {
        if(reportName != ''){
            setRecentWBRDate()
            if(cloudFrontUrl === ""){
                retrieveCloudFrontSignedUrl();
            }
        }

    }, [reportName]);


    return (
        <Container
            header={
                <Header
                    variant="h2"
                    actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button id="cancel" variant="normal" href={'#/reports/'+ report_id}>
                                Cancel
                            </Button>
                            <Button id="retrievePdf" variant="normal" onClick={retrieveReportPDF} disabled={retrievePdfDisabled}>
                                View PDF Preview
                            </Button>
                            {actionButtons}
                        </SpaceBetween>
                    }
                >
                    Adjust report
                </Header>
            }
        >
            <SpaceBetween direction="vertical" size="xl">
            <Form
            >
                <SpaceBetween direction="horizontal" size="xl">
                <FormField
                    id='startdate'
                    label="Report First Week Start date" description="Start date default 10 weeks to end date">
                    <DatePicker
                        onChange={({ detail }) =>
                            setStartDate(detail.value)}
                        value={startDateValue}
                        openCalendarAriaLabel={selectedDate =>
                            "Choose Date" +
                            (selectedDate
                                ? `, selected date is ${selectedDate}`
                                : "")
                        }
                        nextMonthAriaLabel="Next month"
                        placeholder="YYYY/MM/DD"
                        previousMonthAriaLabel="Previous month"
                        todayAriaLabel="Today"
                        disabled
                    />
                </FormField>
                <FormField
                    id='enddate'
                    label="Report Last Week End Date" description="End date default 10 weeks to start date">
                    <DatePicker
                        onChange={({ detail }) =>
                            setEndDate(detail.value)}
                        value={endDateValue}
                        openCalendarAriaLabel={selectedDate =>
                            "Choose Date" +
                            (selectedDate
                                ? `, selected date is ${selectedDate}`
                                : "")
                        }
                        nextMonthAriaLabel="Next month"
                        placeholder="YYYY/MM/DD"
                        previousMonthAriaLabel="Previous month"
                        todayAriaLabel="Today"
                    />
                </FormField>
                </SpaceBetween>
            </Form>

            <Box variant="h2">
                {/*Workaround of loading the generic Cloudfront Viewer Request redirect in an iframe while pdfUrl is not set*/}
                {/*This is to not force a redirect in page while setting Cloudfront Cookies, and will be replaced by PDF after retrieval*/}
                {(cloudFrontUrl != "" && pdfUrl == "")  && <iframe style={{border:"none", width: "100%"}} height={"1px"} src={cloudFrontUrl}/>}
                {(pdfUrl != "" && cloudFrontUrl != "") && <iframe style={{border:"none", width: "100%"}} height={"600px"} src={pdfUrl}/>}
                {(pdfUrl == "" && cloudFrontUrl != "" && showAlert) &&
                <Alert header="Report PDF not generated for current date range" >
                    Please Generate Excel/PDF for given date range
                </Alert>}

            </Box>
            </SpaceBetween>

        </Container>


    );
}