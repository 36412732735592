import React, { useState, useEffect } from 'react';
import {useHistory, useParams} from "react-router-dom";

import { AutosuggestProps, Input, FormField,RadioGroup, Checkbox, PropertyFilter, SpaceBetween, TextContent } from '@amzn/awsui-components-react';
import { PropertyFilterProps, Query } from '@amzn/awsui-components-react/polaris/property-filter/interfaces';
import { PropertyFilterOperator, PropertyFilterToken } from '@amzn/awsui-collection-hooks';
import { FilterInfo, FilterInfoOperators, PolicyFilterProps, PolicyFilterTypes } from '.';
import Autosuggest from "@amzn/awsui-components-react/polaris/autosuggest";
import { getMidwayJwtToken } from "../../../../auth/MidwayJwtToken";
import * as constants from '../../../../common/constants';
import FondueApiFactory from '../../../../fondue-api/FondueApiFactory';
import {Report, ReportUpload, FondueApi} from '../../../../fondue-api/generated-src';
import {DropdownStatusProps} from "@amzn/awsui-components-react/polaris/internal/components/dropdown-status";
import TokenGroup, { type TokenGroupProps }from "@amzn/awsui-components-react/polaris/token-group";
import { type IconProps }from "@amzn/awsui-components-react/polaris/icon";
import ReportUploadFileModal from '../../ReportUploadFileModal';


export interface DerMatchFilterFormProps {
    field: PolicyFilterTypes,
    filters: string,
    onUpdate: (value: string) => void
}

const Operators = [
    ["=", "eq"],
    ["!=", "noteq"],
    [":", "like"],
    ["!:", "notlike"]
] as [PropertyFilterOperator, FilterInfoOperators][];

const regexTag = 'regex'
const likeTag = 'SQL like'

// "=" -> "eq"
const OperatorMap = new Map<PropertyFilterOperator, FilterInfoOperators>(Operators);

// "eq" -> "="
const ReverseOperatorMap = new Map<FilterInfoOperators, PropertyFilterOperator>(Operators.map(x => [x[1], x[0]]));



export default function DerMatchFilterForm({field, filters, ...props }: DerMatchFilterFormProps): JSX.Element {
    const { report_id }= useParams<{report_id: string}>();
    const FondueApi = FondueApiFactory();
    const initialTokens = buildTokensFromFilters(filters);
    const [tokens, setTokens] = useState<TokenGroupProps.Item[]>(initialTokens)

    const newUploadValue = "!upload"

    interface SelectedOption {
       label?: string;
       value?: string;
    }
    const [dropdownValue, setDropdownValue] = useState('');
    const [dropdownStatus, setDropdownStatus] = useState<DropdownStatusProps.StatusType>('loading');
    const [dropdownOptions, setDropdownOptions] = useState<AutosuggestProps.Options>([]);
    const [refreshTable, setRefreshTable] = useState<boolean>(false);

    function convertListToOptions(list){
        return list.map( (val) => ({
            "label":val.upload_name,
            "value":val.upload_name
            })
        )
    }

    const newUploadOption = {
        "label": "Upload CSV file",
        "value": newUploadValue,
        "iconName": "upload"
    } as AutosuggestProps.Option

    async function listReportUploads(FondueApi: FondueApi, resourceType: string, counter: number) {
            await getMidwayJwtToken();
            if(counter < constants.API_RETRY_COUNT){
                await FondueApi.listReportUploads(report_id, resourceType)
                    .then(async(response) => {
                        console.log(response);
                        // Filter out unwanted statuses before setting them to table
                        let uploadList = response.data['ReportUploads'].filter(upload => upload.status=='Validated')

                        let uploadOptions = convertListToOptions(uploadList) as AutosuggestProps.Options
                        setDropdownOptions([...uploadOptions, newUploadOption])
                        setDropdownStatus("finished")
                    })
                    .catch((e) => {
                        setDropdownStatus("error")
                        listReportUploads(FondueApi, resourceType, counter + 1)
                    })
            }
        }
    function refreshReportUploads() {
        // Call listReportUploads which will retry up to 3 times
        listReportUploads(FondueApi, 'account_id',0)
    }
    useEffect(() => {
        (async () => {
            const FondueApi = FondueApiFactory();

            // Call listReportUploads which will retry up to 3 times
            await listReportUploads(FondueApi,'account_id', 0)

            // Setting refreshTable to false so additional updated to EditMetricForm can be picked up
            setRefreshTable(false);
        })();
    }, [refreshTable, FondueApiFactory]);

    function buildTokensFromFilters(filters: string): TokenGroupProps.Item[] {
        if(filters == ""){
            return []
        }
        const policyFilter = JSON.parse(filters) as PolicyFilterProps;
        let tokens = policyFilter.filters.map((f) => {
            var iconName = f.operator == "upload_match"? "file": "key" as IconProps.Name;
            var label = f.value;

            return {"label":label,"iconName":iconName} as TokenGroupProps.Item;
        })
        return tokens
    }

    function buildFiltersFromTokens(newTokens: TokenGroupProps.Item[]): string {
        const filters = newTokens.map<FilterInfo>((token) => {
            const operator:FilterInfoOperators = token.iconName == "file" ? "upload_match" : "eq" as FilterInfoOperators
            return {
                value: token.label!,
                property: field,
                operator: operator
            }
        })

        const result: PolicyFilterProps = {
            caseSensitive: false,
            operation: "or",
            filters: filters
        };
        return JSON.stringify(result);
    }
    function addToken(value: string, type){
        let icon = type == 'csv' ? "file" : "key"
        let newToken = {
            "label":value,
            "iconName":icon as IconProps.Name,
            "type": type
        }
        let newTokens = [...tokens,newToken];
        setTokens(newTokens);
        props.onUpdate(buildFiltersFromTokens(newTokens));
    }
    function onKeyDown(event){
        if(event.key ==='Enter'){
            addToken(dropdownValue,"value");
            setDropdownValue("");
        }
    }

    function onSelect(detail){
        if(detail.selectedOption){
            if(detail.selectedOption.value === newUploadValue){
                setUploadFileModalVisible(true)
                setDropdownValue('')
            }
            else{
                addToken(detail.selectedOption.value,'csv');
                setDropdownValue('')
            }
        }
    }
   const [uploadFileModalVisible, setUploadFileModalVisible]= useState(false);


    return (
        <SpaceBetween size="xs" direction="vertical">
            <Autosuggest
              onChange={({ detail }) => {console.log('change');console.log(detail.value);setDropdownValue(detail.value)}}
              onSelect={({ detail }) => onSelect(detail)}
              onKeyDown={( event ) => {onKeyDown(event.detail)} }
              value={dropdownValue}
              options={dropdownOptions}
              statusType={dropdownStatus}
              filteringType="none"
              placeholder="Enter value"
              empty="No matches found"
            />
            <TokenGroup
                items={tokens}
                onDismiss={({ detail: { itemIndex } }) => {
                    setTokens([
                      ...tokens.slice(0, itemIndex),
                      ...tokens.slice(itemIndex + 1)
                    ]);
                  }}
                limit={3}
            />
            <ReportUploadFileModal
             setVisible={setUploadFileModalVisible}
             visible={uploadFileModalVisible}
             preselectedResourceType={"account_id"}
             addToken={addToken}
            />
        </SpaceBetween>

    );
}