import { useEffect, useState, useCallback } from 'react';
import {useHistory, useParams} from "react-router-dom";
import { useCollection } from '@amzn/awsui-collection-hooks';
import Button from '@amzn/awsui-components-react/polaris/button';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import TextFilter from '@amzn/awsui-components-react/polaris/text-filter';
import { COLUMN_DEFINITIONS, getMatchesCountText, EmptyState, paginationLabels } from './table-config';
import FondueApiFactory from '../../fondue-api/FondueApiFactory';
import {Report, ReportItem, FondueApi, DisableReportItemRequest} from '../../fondue-api/generated-src';
import { States } from "../../common/States";
import ButtonDropdown from "@amzn/awsui-components-react/polaris/button-dropdown";
import ButtonDropdownProps from '@amzn/awsui-components-react/polaris/button-dropdown/interfaces';
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Input from "@amzn/awsui-components-react/polaris/input";
import {
    Box,
    CollectionPreferences,
    FormField,
    Header,
    Table,
    TableProps,
    SpaceBetween,
    Badge, Alert, ProgressBar, Textarea
} from '@amzn/awsui-components-react/polaris';
import { getMidwayJwtToken } from "../../auth/MidwayJwtToken";


export interface ReportTableProps {
    setState: (x: States) => void;
    state: States;
    refreshTable: boolean;
    setRefreshTable: (x: boolean) => void;
    report: Report;
    setReportItem: (reportItem: ReportItem) => void;
    setAddDisabled: (x: boolean) => void;
    setEditDisabled: (x: boolean) => void;
    setAddEditInfoDisabled: (x: boolean) => void;
}


export default function ReportItemsTable({setState, state, refreshTable, setRefreshTable, report, setReportItem, setAddDisabled, setEditDisabled, setAddEditInfoDisabled}: ReportTableProps) {
    Object.freeze(Object.prototype);
    const { report_id }= useParams<{report_id: string}>();
    const [allItems, setAllItems] = useState<ReportItem[]>();
    const [selectedItem, setSelectedItem] = useState<ReportItem[]>();

    const [inputValue, setInputValue] = useState("");
    const [modalVisible, setModalVisible] = useState(false);
    const [modalErrorMessage, setModalErrorMessage] = useState("");

    const [saveDisabled, setSaveDisabled] = useState(true);

    const [initialItems, setInitialItems] = useState<number[]>();
    const [selectedItems, setSelectedItems] = useState<ReportItem[]>();

    const [pageSize, setPageSize] = useState(20);

    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [deleteMetricReason, setDeleteMetricReason] = useState("");
    const [deleteProgressPercentage, setDeleteProgressPercentage] = useState(0);
    const [deleteProgressInfo, setDeleteProgressInfo] = useState("");
    const [deleteReasonTextInvalid, setDeleteReasonTextInvalid] = useState(false);
    const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(false);
    const [cancelButtonDisabled, setCancelButtonDisabled] = useState(false);

    const FondueApi = FondueApiFactory();

    function getItems(){
        var curItems:ReportItem[] = []
        if(allItems){
            allItems.forEach(function(item){
                curItems.push(item);
            })
        }
        return curItems;
    }

    function moveMetricUp(){
        if(selectedItems && selectedItems.length >= 1 && selectedItems[0].metric_number && allItems){
            var curItems = getItems();
            // Order so that top moves first so the selected items wont swap with each other
            var orderedItems: ReportItem[] = []
            selectedItems.forEach(function(item){
               if(item.metric_number)
                   orderedItems.push(item)
            });
            orderedItems.sort((a,b) => {
               if(a.metric_number && b.metric_number){
                   return a.metric_number - b.metric_number;
               }
               return 0;
            });

            orderedItems.forEach(function(item){
                if(item && item.metric_number && item.metric_number > 1){
                    var toFind = item.metric_number-1;
                    curItems.forEach(function(curItem){
                        if(curItem && curItem.metric_number && curItem.metric_number == toFind){
                            curItem.metric_number = item.metric_number;
                        }
                    })
                    item.metric_number--;
                }
            });
            setAllItems(curItems);
            setModalVisible(false);
            setSaveDisabled(false);
        }
    }

    function moveMetricDown(){
        if(selectedItems && selectedItems.length >= 1 && selectedItems[0].metric_number && allItems){
            var curItems = getItems();
            // Order so that bottom moves first so the selected items wont swap with each other
            var orderedItems: ReportItem[] = []
            selectedItems.forEach(function(item){
               if(item.metric_number)
                   orderedItems.push(item)
            });
            orderedItems.sort((a,b) => {
               if(a.metric_number && b.metric_number){
                   return b.metric_number - a.metric_number;
               }
               return 0;
            });

            orderedItems.forEach(function(item){
                if(item && item.metric_number && item.metric_number < allItems.length){
                    var toFind = item.metric_number+1;
                    curItems.forEach(function(curItem){
                        if(curItem && curItem.metric_number && curItem.metric_number == toFind){
                            curItem.metric_number = item.metric_number;
                        }
                    })
                    item.metric_number++;
                }
            });
            setAllItems(curItems);
            setModalVisible(false);
            setSaveDisabled(false);
        }
    }

    function moveTableMetric(spot){
        if(selectedItems && selectedItems.length >= 1 && selectedItems[0] && allItems){
            if(spot <= 0){
                setModalErrorMessage("Position to move to cannot be below or equal to 0");
                return;
            }
            if(spot > allItems.length){
                setModalErrorMessage(`Position to move to cannot be outside the current maximum spot (${allItems.length})`)
                return;
            }
            // In case there's a call to move in between the selected items, move up the items to the bottom of the specified spots and then move down the items to the top of the specified spots
            var moveDownItems:ReportItem[] = []
            var moveUpItems: ReportItem[] = []
            selectedItems.forEach(function(item){
                if(item.metric_number && item.metric_number > spot)
                    moveUpItems.push(item);
                else
                    moveDownItems.push(item);
            });
            moveUpItems.sort((a,b) => {
                if(a.metric_number && b.metric_number){
                    return a.metric_number - b.metric_number;
                }
                return 0;
            });
            moveDownItems.sort((a,b) => {
                if(a.metric_number && b.metric_number){
                    return b.metric_number - a.metric_number;
                }
                return 0;
            });

            var curItems = getItems();
            var startSpot = spot;
            var endSpot = spot+selectedItems.length-1;
            spot += moveDownItems.length
            moveUpItems.forEach(function(selectedItem){
                var cur_metric_num = selectedItem.metric_number as number;
                curItems.forEach(function(item){
                    if(item && item.metric_number){
                        if(item.metric_number == cur_metric_num){
                            item.metric_number = spot;
                        }
                        else if(cur_metric_num > spot && item.metric_number >= spot && item.metric_number < cur_metric_num){
                            item.metric_number += 1;
                        }
                        else if(cur_metric_num < spot && item.metric_number <= spot && item.metric_number > cur_metric_num){
                            item.metric_number -= 1;
                        }
                    }
                })
                spot+=1;
            })
            spot = endSpot-moveUpItems.length;
            moveDownItems.forEach(function(selectedItem){
                            var cur_metric_num = selectedItem.metric_number as number;
                            curItems.forEach(function(item){
                                if(item && item.metric_number){
                                    if(item.metric_number == cur_metric_num){
                                        item.metric_number = spot;
                                    }
                                    else if(cur_metric_num > spot && item.metric_number >= spot && item.metric_number < cur_metric_num){
                                        item.metric_number += 1;
                                    }
                                    else if(cur_metric_num < spot && item.metric_number <= spot && item.metric_number > cur_metric_num){
                                        item.metric_number -= 1;
                                    }
                                }
                            })
                            spot-=1;
                        })
            setAllItems(curItems);
            setModalVisible(false);
            setSaveDisabled(false);
        }
        else{
            setModalErrorMessage('No object was selected')
        }
    }

    function getOrder(){
        var curItems = allItems as ReportItem[];
        curItems.sort((n1,n2)=> {
            if(!n1.metric_number || !n2.metric_number || n1.metric_number == n2.metric_number){
                return 0;
            }
            else if(n1.metric_number > n2.metric_number){
                return 1;
            }
            else{
                return -1;
            }
        });
        var output: string[] = [];
        curItems.forEach(function(item){
            output.push(item.id);
        })
        return output;
    }

    function showDeleteItemsModal(items) {
        if (items.length > 0){
            setDeleteModalVisible(true);
        }
    }

    async function deleteSelectedItems() {
        await getMidwayJwtToken();
        if (!!selectedItems){
            // Check reason
            if(deleteMetricReason.length === 0){
                // Show error
                setDeleteReasonTextInvalid(true);
                return;
            }

            // Everything is ready to delete
            setDeleteReasonTextInvalid(false);
            setDeleteButtonDisabled(true);
            setCancelButtonDisabled(true);

            let deleteProgressPercentage = 0;
            let deleteProgressDelta = 100/selectedItems.length;
            let successCount = 0;
            for (let i=0; i < selectedItems.length; i++){
                setDeleteProgressInfo("Deleting '" + selectedItems[i].name + "'");

                const deleteReportItemObj: DisableReportItemRequest = {
                    message: deleteMetricReason
                };

                // Calling updateReportItem
                await FondueApi.disableReportItem(selectedItems[i].id, deleteReportItemObj)
                    .then((response) => {
                        deleteProgressPercentage += deleteProgressDelta;
                        setDeleteProgressPercentage(deleteProgressPercentage);
                        successCount +=1
                    })
                    .catch((e)=> {
                        // Show error and stop deleting items
                        setDeleteProgressInfo('Failed to delete ' + selectedItems[i].name);
                        return;
                    });
            }
            if (successCount === selectedItems.length){
                setDeleteProgressInfo("Successfully deleted all selected items");
                // Show info box
                setAddDisabled(true);
                setEditDisabled(true);
                setAddEditInfoDisabled(false);
            }
            setCancelButtonDisabled(false);
        }
    }

    async function dismissDeleteModal(){
        // Reset modal
        setDeleteModalVisible(false);
        setDeleteProgressPercentage(0);
        setDeleteMetricReason("");
        setDeleteProgressInfo("");
        setDeleteButtonDisabled(false);
        setCancelButtonDisabled(false);
        setRefreshTable(true);
        setState(States.success);
    }

    function metricItemAction(event){
        switch (event.detail.id) {
            case "mv":
                togglePopup();
                break;
            case "reset":
                var curItems = getItems();
                for (let i = 0; i < curItems.length; i++) {
                    if(curItems[i] && curItems[i].metric_number && initialItems && initialItems[i]){
                        curItems[i].metric_number = initialItems[i];
                    }
                }
                setAllItems(curItems);
                setSaveDisabled(true);
                break;
            case "del":
                showDeleteItemsModal(selectedItems);
                break;
        }
    }

    function togglePopup(){
        setModalErrorMessage('')
        setModalVisible(true);
    }


    async function listReportMetrics(FondueApi: FondueApi, counter: number) {
        await getMidwayJwtToken();
        if(counter < 3){
            await FondueApi.listReportMetrics(report_id)
                .then(async(response) => {
                    setSelectedItems([]);
                    setAllItems(response.data['results'])
                    setState(States.success)
                    var items:  number[] = []
                    response.data['results'].forEach(function(item){
                        if(item.metric_number)
                            items.push(item.metric_number);
                     })
                    setInitialItems(items);
                })
                .catch((e) => {
                    setState(States.error)
                    listReportMetrics(FondueApi, counter + 1)
                })
        }
    }


    useEffect(() => {
        (async () => {
            const FondueApi = FondueApiFactory();

            // Setting refreshTable to false so additional updated to EditMetricForm can be picked up
            setRefreshTable(false);

            // Call listReportsMetrics which will retry up to 3 times
            await listReportMetrics(FondueApi, 0)
        })();
    }, [refreshTable, setAllItems, FondueApiFactory]);



    const { items,  filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
        allItems || [],
        {
            filtering: {
                empty: (
                    <EmptyState
                        title="No records"
                        subtitle="No records to display."
                    />
                ),
                noMatch: (
                    <EmptyState
                        title="No matches"
                        subtitle="We can't find a match."
                    />
                )
            },
            pagination: {
                pageSize: pageSize
            },
            sorting: { defaultState: { sortingColumn: { sortingField: "metric_number" } } },
            selection: {}
        }
    );

    function cancel(){
        setModalVisible(false);
    }

    function moveMetric(){
        moveTableMetric(parseInt(inputValue))
    }

    function savePlacements(){
        setState(States.savingReport)
        setSaveDisabled(true)
        getMidwayJwtToken();
        var updatedReport: Report = report
        updatedReport["report_item_order"] = getOrder()
        const fondueApi = FondueApiFactory();

        fondueApi.updateReport(report_id, updatedReport)
            .then((response) => {
                setState(States.saveReportSuccess)
            })
            .catch((e) => {
                return;
            })
        var items:  number[] = []
        getItems().forEach(function(item){
            if(item.metric_number)
                items.push(item.metric_number);
        })
        setInitialItems(items);
    }

    function handleSelect(detail) {
        setSelectedItems(detail.selectedItems);
        if (detail.selectedItems.length === 1){
            const selecteditem = detail.selectedItems[0];
            const reportItem: ReportItem = {
                id: selecteditem.id,
                name: selecteditem.name,
                type: selecteditem.type,
                report_id: selecteditem.report_id,
                description: (selecteditem.description) ? (selecteditem.description) : "",
                report_item_config: selecteditem.report_item_config,
                query: selecteditem.query,
                format: (selecteditem.format) ? (selecteditem.format) : "",
                bindle: selecteditem.bindle
            };
            setReportItem(reportItem);
            setSelectedItem(detail.selectedItems);
            setAddEditInfoDisabled(true);
            setAddDisabled(true);
            setEditDisabled(false);
        }
        else {
            // 0, 2 or more items are selected
            setAddEditInfoDisabled(false);
            setAddDisabled(true);
            setEditDisabled(true);
        }

    }

    const defaultSort: TableProps.SortingColumn<ReportItem> = {
      sortingField: "metric_number",
    }

    //const { selectedItems } = collectionProps;


    function showAddMetricComponent(){
        setAddDisabled(false);
        setEditDisabled(true);
        setAddEditInfoDisabled(true);
    }

    const addMetricButton = (
        <Button variant="primary" onClick={showAddMetricComponent}>
            Add Item
        </Button>
    );

    const actionsButton = (
        <ButtonDropdown
            id ="btnDropdown"
            items={[
                { text: "Edit", id: "ed", disabled: !selectedItems || selectedItems.length != 1 },
                { text: "Delete", id: "del", disabled: false },
                { text: "Duplicate", id: "rm", disabled: !selectedItems || selectedItems.length != 1 },
                { text: "Move to row #", id: "mv", disabled: false },
                { text: "Reset order", id: "reset", disabled: false }
            ]}
            onItemClick={(event) => metricItemAction(event)}
        >
            Actions
        </ButtonDropdown>
    );

    const moveUpButton = (
        <Button id="moveUp" onClick={(event) => moveMetricUp()}>Move Up</Button>
    )
    const moveDownButton = (
        <Button id="moveDown" onClick={(event) => moveMetricDown()}>Move Down</Button>
    )
    const savePlacementButton = (
        <Button id="savePlacement" variant="primary" disabled={saveDisabled} onClick={(event) => savePlacements()}>Save </Button>
    )

    return (
      <div>
        <Table
            {...collectionProps}
            loading={!allItems && state === States.loading}
            loadingText="Loading instances"
            selectionType="multi"
            selectedItems={selectedItems}
            header={
                <Header
                    counter={
                        allItems &&
                        (selectedItems!.length ? `(${selectedItems!.length}/${allItems.length})` : `(${allItems.length})`)
                    }
                    actions={
                        <SpaceBetween direction="horizontal" size="m">
                            {actionsButton}
                            {moveUpButton}
                            {moveDownButton}
                            {savePlacementButton}
                            {addMetricButton}
                        </SpaceBetween>
                    }
                >
                    Items
                </Header>
            }
            columnDefinitions={COLUMN_DEFINITIONS}
            items={items}
            onSelectionChange={({ detail }) => handleSelect(detail)}
            pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
            filter={
                <TextFilter
                    {...filterProps}
                    countText={getMatchesCountText(filteredItemsCount!)}
                    filteringAriaLabel="Filter records"
                />
            }
            preferences={
                <CollectionPreferences
                    title="Preferences"
                    confirmLabel="Confirm"
                    cancelLabel="Cancel"
                    preferences={{
                        pageSize: pageSize,
                    }}
                    pageSizePreference={{
                        title: "Select page size",
                            options: [
                            { value: 20, label: "20 items" },
                            { value: 50, label: "50 items" },
                            { value: 100, label: "100 items" }
                        ]
                    }}
                    onConfirm={(event) => setPageSize(event.detail.pageSize as number)}
                />
            }
        />
        <Modal
          id ="moveModal"
          onDismiss={() => setModalVisible(false)}
          visible={modalVisible}
          closeAriaLabel="Close modal"
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="link" onClick={(event) => cancel()}>Cancel
                </Button>
                <Button id = "confirm" variant="primary" onClick={(event) => moveMetric()}>Ok</Button>
              </SpaceBetween>
            </Box>
          }

        >
        <FormField
              id = "reorderFormField"
              description="Position to move item."
              label="Reorder Item"
              errorText={modalErrorMessage}
            >
          <Input
            id="reorderInput"
            value={inputValue}
            onChange={event =>
              setInputValue(event.detail.value)
            }
            type="number"
          />
          </FormField>
        </Modal>
          <Modal
              id ="deleteMetricModal"
              onDismiss={() => dismissDeleteModal()}
              visible={deleteModalVisible}
              closeAriaLabel="Close modal"
              header="Delete Item"
              footer={
                  <Box float="right">
                      <SpaceBetween direction="horizontal" size="xs">
                          <Button variant="normal" onClick={(event) => dismissDeleteModal()} disabled={cancelButtonDisabled}>Cancel</Button>
                          <Button id = "confirm" variant="primary" onClick={(event) => deleteSelectedItems()} disabled={deleteButtonDisabled}>Delete</Button>
                      </SpaceBetween>
                  </Box>
              }>
            <div>
                <Alert type="warning">
                    You won't be able to restore deleted items through Fondue.
                    <br/>Please cut ticket to 'AWS/Auth/AWSID Business Metrics' CTI to restore items.
                </Alert>
                <p>Please enter a reason to delete following item(s):</p>
                <SpaceBetween direction="vertical" size="m">
                <ol>
                    {selectedItems?.map(item => (
                        <li key={item.id}>
                            {item.name} <Badge>{(item.type === 'query_single_result' || item.type === 'query_multiple_result') ? 'query' : item.type}</Badge>
                        </li>
                    ))}
                </ol>
                    <Textarea
                        onChange={({ detail }) => setDeleteMetricReason(detail.value)}
                        value={deleteMetricReason}
                        placeholder="Please enter a valid reason"
                        invalid={deleteReasonTextInvalid}
                    />
                <ProgressBar
                    value={deleteProgressPercentage}
                    label="Delete Progress"
                    additionalInfo={deleteProgressInfo}/>
                </SpaceBetween>
            </div>
          </Modal>
        </div>
    );
}
