import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import { Breadcrumbs, ServiceNavigation } from '../navigation';
import { appLayoutLabels } from '../../common/labels';
import {useEffect, useState} from "react";
import Flashbar, {FlashbarProps} from "@amzn/awsui-components-react/polaris/flashbar";
import ToolsPanel from "../reports/ToolsPanel";
import ReportsFormPage from "./FormPage"


export default function ReportsFormView() {

    const [flashbar, setFlashbar] = useState<FlashbarProps.MessageDefinition[]>([])

    return (
        <AppLayout
            navigation={<ServiceNavigation />}
            breadcrumbs={<Breadcrumbs items={[
                { text: 'Reports Table', href: '#/reports' },
                { text: 'Create Report Form', href: '' }]} />}
            content={<ReportsFormPage setFlashbar={setFlashbar}/> }
            contentType="form"
            notifications={<Flashbar items={flashbar} />}
            toolsOpen={false}
            tools={<ToolsPanel />}
            ariaLabels={appLayoutLabels}
        />
    );
}
